import React from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { customTheme, ERR_STATUS_500_MSG } from 'const';

// icons
import InfoIcon from '@material-ui/icons/Info';
import PersonIcon from '@material-ui/icons/Person';

import * as orderActions from 'redux/order/actions';
import * as authActions from 'redux/auth/actions';

import { LoadingIndicator, SelectDiningArea, HeaderAndMenu } from 'components';
import ResidentInfo from './ResidentInfo';

import * as _ from 'lodash';

const styles = theme => ({
    residentName: {
        fontSize: '2rem',
        lineHeight: '40px'
    }
})

class Consumption extends React.Component {

    state = {
        loading: true,
        errorMsg: '',
        residents: [],
        visibleResidents: [],
        diningAreaSelectOpen: false,
        selectedDiningArea: this.props.selectedDiningArea.Name,
        selectedDiningAreaId: this.props.selectedDiningArea.Id,
    }

    openSelectDiningArea = () => {
        this.setState({ diningAreaSelectOpen: true });
    };

    diningAreaSelected = (diningAreaId, diningAreaName) => {
        this.setState({ diningAreaSelectOpen: false });
        if (diningAreaName) this.setState({ 
            selectedDiningArea: diningAreaName, 
            selectedDiningAreaId: diningAreaId 
        }, () => {
            this.getResidentsByDiningArea(diningAreaId)
        });
    };

    search(searchStr){
        let filteredResdients = _.filter(this.state.residents, resident=>{
            let residentName = resident.Name;
            return residentName.toLowerCase().indexOf(searchStr.toLowerCase())===0
        });
        if(filteredResdients.length===0 && searchStr===''){
            filteredResdients = this.state.residents;
        }
        this.setState({
            visibleResidents: filteredResdients
        });
    }

    componentDidMount(){
        this.getResidentsByDiningArea(this.state.selectedDiningAreaId)
    }

    getResidentsByDiningArea(diningAreaId) {
        this.setState({ loading: true });
        this.props.getResidentsByDiningArea(diningAreaId, response => {
            const { residents, error } = response;
            if(error){
                var errorMsg = '';
                if(error==='Failed'){
                    errorMsg = `Request Failed`
                } else if (error === ERR_STATUS_500_MSG) {
                    errorMsg = ERR_STATUS_500_MSG;
                } else{
                    errorMsg = error;
                }
                this.setState({
                    loading: false
                });
                this.props.showError(errorMsg);
            }
            if(residents){
                this.setState({
                    loading: false,
                    residents: residents,
                    visibleResidents: residents
                });
                this.props.diningAreas.forEach(area => {
                    if(area.Id === diningAreaId) {
                        this.props.selectArea(area);
                    }
                })
            }

        })
    }

    gotoNext(residentId) {
        this.setState({loading: true});
        this.props.getActiveMealsByResident(residentId, response => {
            const { activeMeals, error } = response;
            if(error){
                var errorMsg = '';
                if(error==='Failed'){
                    errorMsg = `Request Failed`;
                }
                else{
                    errorMsg = error;
                }
                this.setState({
                    loading: false,
                });
                this.props.showError(errorMsg);
            }
            if(activeMeals){

                this.props.history.push(`/consumption/${residentId}/${activeMeals.Days[0].Meals[0].SetupId}`)

            }
        })
    }

    renderResidents(){
        const { classes, holdedOrderArray } = this.props;
        let residentsList = [];
        
        _.forEach(this.state.visibleResidents, resident => {
            let flag = true;
            _.forEach(holdedOrderArray, pending => {
                if (pending.Order.ResidentId === resident.Id) {
                    flag = false;
                }
            })
            if (flag) {
                residentsList.push(
                    <ListItem className={classes.residentName} button onClick={()=>this.gotoNext(resident.Id)} key={resident.Id}>
                        <ListItemIcon><PersonIcon style={{transform: 'scale(1.5)'}}/></ListItemIcon>
                        <ListItemText primary={<span className={classes.residentName}>{resident.Name}</span>} />
                        <ListItemSecondaryAction>
                            {/* <IconButton onClick={() => this.setState({modalOpen: true, residentId: resident.Id})} > */}
                            <IconButton onClick={() => this.props.setModalComponent(<ResidentInfo residentId={resident.Id} showError={this.props.showError}/>)} >
                                <InfoIcon style={{transform: 'scale(1.2)'}}/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                )
            }
        })      
        return <List>{residentsList}</List>;    
    }

    render() {
        return (
            <div className="order-container">
                <MuiThemeProvider theme={customTheme}>
                    <HeaderAndMenu title="DiningRD" />
                    {this.state.loading ? <LoadingIndicator /> : (
                        
                        <div className="paperContent customScrollbar">
                            <Grid container justify="flex-end">
                                {/* <TextField
                                    style={{color: 'red'}}
                                    type="search"
                                    onChange={(event)=>this.search(event.target.value)}
                                    placeholder="Search by Name"
                                    InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                        <Search />
                                        </InputAdornment>
                                    ),
                                    }}
                                /> */}
                                <Button
                                    fullWidth
                                    variant="raised"
                                    onClick={this.openSelectDiningArea}
                                >
                                    Dining Area: {this.state.selectedDiningArea}
                                </Button>
                                <SelectDiningArea
                                    open={this.state.diningAreaSelectOpen}
                                    itemSelected={this.diningAreaSelected}
                                    title="Select Dining Area"
                                />
                            </Grid>
                            {
                                this.renderResidents()
                            }
                        </div>
                        
                        )
                    }
            </MuiThemeProvider>
            </div>
        );
    }
}

export default connect(state => ({
    residents: state.authReducer.userData.residents,
    diningAreas: state.authReducer.userData.diningAreas,
    selectedDiningArea: state.authReducer.selectedArea,
    holdedOrderArray: state.orderReducer.holdedOrderArray
}), {
    ...orderActions, ...authActions
})(withStyles(styles)(Consumption))
