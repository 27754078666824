import React from 'react';
import { connect } from 'react-redux';

import Drawer from '@material-ui/core/Drawer';
import MenuItem  from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import { Link } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import * as authActions from 'redux/auth/actions';

class LeftDrawer extends React.Component {

	render() {
		return (
			<div>

				<Drawer
					width={200}
					open={this.props.open}
					onClose={() => this.props.toggleMenu()}
				>
					<AppBar position="static">
						<Toolbar>
							<Typography variant="title" color="inherit">
								Main Menu
							</Typography>
						</Toolbar>
					</AppBar>
					<MenuItem onClick={this.props.toggleMenu}
						component={Link} to="/home">
						Home
                    	</MenuItem>
					<MenuItem onClick={this.props.toggleMenu}
						component={Link} to="/takeorder/residents">
						Take Orders
						</MenuItem>
					{/* <MenuItem onClick={this.props.toggleMenu}
						component={Link} to="/consumption">
						Record Consumption
						</MenuItem> */}
					{this.props.permissions.indexOf("Setup Meals") !== -1 &&
					<MenuItem onClick={this.props.toggleMenu}
						component={Link} to="/meal">
						Start a Meal
						</MenuItem>}
					<MenuItem onClick={() => {
						this.props.toggleMenu();
						this.props.logout();
					}}
						component={Link} to="/">
						Logout
						</MenuItem>
				</Drawer>
			</div>
		);
	}
}

export default connect(state => ({
    permissions: state.authReducer.userData.permissions
}), {...authActions} )(LeftDrawer)