import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import { Login, Home, TakeOrder, Meal, Consumption, Record } from './containers';

export default props => {
    return (
        <div className="app-container">
            <Route exact path="/" render={()=><Redirect to="/"/>}/>
            {props.token ?
                <Route exact path="/" render={()=><Redirect to="/home"/>}/> : 
                <Route exact path="/" component={Login} />
            }
            {!props.token ?
                <Route exact path="/home" render={()=><Redirect to="/"/>}/> : 
                <Route exact path="/home" component={Home} />
            }

            {!props.token ?
                <Route exact path="/takeorder/residents" render={()=><Redirect to="/"/>}/> : 
                <Route exact path="/takeorder/residents" component={TakeOrder} />
            }

            {!props.token ?
                <Route exact path="/takeorder/residents/meals/:residentId" render={()=><Redirect to="/"/>}/> : 
                <Route exact path="/takeorder/residents/meals/:residentId" component={TakeOrder} />
            }
            
            {!props.token ?
                <Route exact path="/takeorder/residents/order/:residentId/:tableSideSetupId" render={()=><Redirect to="/"/>}/> : 
                <Route exact path="/takeorder/residents/order/:residentId/:tableSideSetupId" component={TakeOrder} />
            }

            {!props.token ?
                <Route exact path="/takeorder/guest" render={()=><Redirect to="/"/>}/> : 
                <Route exact path="/takeorder/guest" component={TakeOrder} />
            }

            {!props.token ?
                <Route exact path="/takeorder/guest/order" render={()=><Redirect to="/"/>}/> : 
                <Route exact path="/takeorder/guest/order" component={TakeOrder} />
            }

            {!props.token ?
                <Route exact path="/takeorder/cooksticket" render={()=><Redirect to="/"/>}/> : 
                <Route exact path="/takeorder/cooksticket" component={TakeOrder} />
            }

            {!props.token ?
                <Route exact path="/takeorder/pending" render={()=><Redirect to="/"/>}/> : 
                <Route exact path="/takeorder/pending" component={TakeOrder} />
            }

            {!props.token ?
                <Route exact path="/takeorder/submitted" render={()=><Redirect to="/"/>}/> : 
                <Route exact path="/takeorder/submitted" component={TakeOrder} />
            }

            {!props.token ?
                <Route exact path="/consumption" render={()=><Redirect to="/"/>}/> : 
                <Route exact path="/consumption" component={Consumption} />
            }

            {!props.token ?
                <Route exact path="/consumption/:residentId/:mealId" render={()=><Redirect to="/"/>}/> : 
                <Route exact path="/consumption/:residentId/:mealId" component={Record} />
            }

            {!props.token ?
                <Route exact path="/meal" render={()=><Redirect to="/"/>}/> : 
                <Route exact path="/meal" component={Meal} />
            }
        </div>
    );
}