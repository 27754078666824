import React from "react";
import { connect } from "react-redux";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";

import Select from "@material-ui/core/Select";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
// import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Media from "react-media";

// icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Warning from "@material-ui/icons/Warning";
import Favorite from "@material-ui/icons/Favorite";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

import * as orderActions from "redux/order/actions";
import { LoadingIndicator } from "components";
import * as _ from "lodash";

import { withStyles } from "@material-ui/core/styles";

import { Colors } from "const";
import { Checkbox } from "@material-ui/core";
import CustomModal from "../../../components/CustomModal";
import SubRecipe from "../../../components/SubRecipes";

import diamond from "assets/imgs/diamond.png";

import ModalImage from "react-modal-image";
import CurrencyInput from "react-currency-input";
import HasSubRecipesIcon from "@material-ui/icons/Dehaze";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";

const printStyles = {
  generalInfo: {
    display: "flex",
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  divSpace: {
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  rightText: {
    // width: "80px",
    // textAlign: 'right'
  },
};

const styles = (theme) => ({
  warning: {
    color: Colors.yellow,
  },
  menuContainer: {
    marginTop: "20px",
  },
  alternateContainer: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  alternateTitle: {
    marginBottom: "20px",
    marginLeft: "24px",
  },
  btnContainer: {
    marginTop: "15px",
  },
  btn: {
    flex: 1,
    margin: "5px",
  },
  modalItemContainer: {
    marginTop: "20px",
    width: "100%",
  },
  note: {
    width: "100%",
    border: "1px solid",
    borderColor: Colors.yellow,
    padding: "5px",
  },
  generalText: {
    fontSize: "1rem",
  },
  highlightedText: {
    fontSize: "1rem",
    color: Colors.green,
  },
  toggleRow: {
    cursor: "pointer",
  },
  generalDes: {
    fontSize: "2rem",
  },
  highlightedDes: {
    fontSize: "2rem",
    // color: Colors.green
  },
  generalDesSmall: {
    fontSize: "1rem",
  },
  highlightedDesSmall: {
    fontSize: "1rem",
    // color: Colors.green
  },
  desContainer: {
    textAlign: "center",
  },
  subItemContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  guestPrice: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const GuestType = ["None", "Guest", "Employee", "Other"];

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.modalref = React.createRef();
  }

  state = {
    checkedB: false,
    loading: true,
    menuInfo: null,
    orderInfo: {
      MenuItems: [],
      AlternateItems: [],
      Order: {
        Id: 0,
        Note: "",
        Guest: "",
      },
    },
    modalItems: {
      MenuItems: [],
      AlternateItems: [],
    },
    tables: this.props.diningAreas[0].Tables,
    outOfBuilding: false,
    declinedMeal: false,
    selectedDiningArea: "",
    selectedTable: "",
    note: "",
    itemsChecked: {
      AlternateItems: {},
      MenuItems: {},
    },
    holdFlag: "false",
    print: false,
    directPrint: false,
    fluidRestriction: false,
    infoNotes: null,
    infoBeverages: null,
    roomBed: null,
    fromSP: false,
    isOpenModal: false,
    recipeimageurl: "",
    showAdditionalCharges: false,
    AdditionalCharges: 0.0,
    menuChoicesWithSubRecipe: [],
    alternateMenuChoicesWithSubRecipe: [],
    selectedSubRecipes: [
      {
        ParentRecipeId: "",
        RecipeName: "",
        RecipeId: 0,
        Quantity: 1,
        isAlternateItem: false,
      },
    ],
    modalSubRecipeOpen: false,
    currentSubRecipe: null,
    collapseRowStatus: [
      {
        rowId: 0,
        colStatus: false,
      },
    ],
    ISAllSelected: true,
    mealsAddOn: null
  };

  removeSeletedSubRecipeFromList(parentRecipeID, altrecipe) {
    let subrec = [...this.state.selectedSubRecipes];
    let colrow = [...this.state.collapseRowStatus];

    subrec = subrec.filter((i) => {
      return i.ParentRecipeId !== parentRecipeID || i.isAlternateItem !== altrecipe;
    });

    colrow = colrow.filter((i) => {
      return i.rowid !== parentRecipeID || i.isAlternateItem !== altrecipe;
    });

    this.setState({ selectedSubRecipes: subrec, collapseRowStatus: colrow }, () => {
      console.log(this.state.selectedSubRecipes);
    });
  }

  handleChange(item, choice, checked) {
    const { classes } = this.props;
    let orderInfo = this.state.orderInfo;
    let modalItems = this.state.modalItems;
    let itemsChecked = this.state.itemsChecked;
    let menuInfo = this.state.menuInfo;
    console.log("item:", item);
    console.log("choice:", choice);
    console.log("checked:", checked);
    if (checked) {
      orderInfo[choice].push({
        ItemId: item.RecipeID,
        Quantity: 1,
        GuestPrice: item.GuestPrice,
        MenuCategory: item.MenuCategory,
        IsSubstitution: item.IsSubstitution,
      });
      item.Quantity = 1;
      modalItems[choice].push(item);
      itemsChecked[choice][item.RecipeID] = true;
      menuInfo.GuestPrice = menuInfo.GuestPrice + item.GuestPrice;
      if (this.state.fluidRestriction && item.ApplicableToFluidRestriction) {
        this.props.setModalComponent(
          <Grid container justify="center" alignItems="center" direction="column">
            <div>Fluid Restriction</div>
            <br />
            <div>
              This resident has a fluid restriction.
              <br />
              Make sure that this selection doesn't exceed the resident's restriction.
            </div>
            <br />
            <div>
              <Button variant="raised" color="primary" onClick={() => this.props.handleCloseModal()} className={classes.btn}>
                Ok
              </Button>
            </div>
          </Grid>
        );
      }
    } else {
      orderInfo[choice] = orderInfo[choice].filter((i) => {
        if (i.ItemId === item.RecipeID) {
          menuInfo.GuestPrice = menuInfo.GuestPrice - i.GuestPrice * i.Quantity;
        }
        return i.ItemId !== item.RecipeID;
      });
      modalItems[choice] = modalItems[choice].filter((i) => {
        return i.RecipeID !== item.RecipeID;
      });
      itemsChecked[choice][item.RecipeID] = false;
      this.removeSeletedSubRecipeFromList(item.RecipeID, item.IsAlternateRecipe);
    }
    this.setState({
      orderInfo: orderInfo,
      modalItems: modalItems,
      itemsChecked: itemsChecked,
      menuInfo: menuInfo,
    });
  }

  selectServingSize(item, choice, value) {
    let orderInfo = this.state.orderInfo;
    let modalItems = this.state.modalItems;
    let menuInfo = this.state.menuInfo;
    _.forEach(orderInfo[choice], (i) => {
      if (i.ItemId === item.RecipeID) {
        menuInfo.GuestPrice = menuInfo.GuestPrice + (value - i.Quantity) * i.GuestPrice;
        i.Quantity = value;
      }
    });
    _.forEach(modalItems[choice], (k) => {
      if (k.RecipeID === item.RecipeID) {
        k.Quantity = value;
      }
    });
    this.setState({
      orderInfo: orderInfo,
      modalItems: modalItems,
      menuInfo: menuInfo,
    });
  }

  selectTables(event) {
    let diningAreas = this.props.diningAreas;
    let selectedArea = event.target.value;
    var tb = null;
    _.forEach(diningAreas, (item) => {
      if (item.Id === selectedArea) {
        this.setState({
          tables: item.Tables,
          selectedDiningArea: selectedArea,
        });

        if (item.Tables.length > 0)
          tb = item.Tables[0]
      }
    });

    if (tb != null) {
      this.setState({
        selectedTable: tb.Name
      })
    }
  }

  selectTable(event) {
    this.setState({
      selectedTable: event.target.value,
    });
  }

  handleCloseModal = () => {
    this.setState({ modalSubRecipeOpen: false });
  };

  submitOrder(option) {
    this.props.handleCloseModal();
    this.setState({
      loading: true,
    });
    let menuInfo = Object.assign({}, this.state.menuInfo);
    let orderInfo = this.state.orderInfo;
    menuInfo.MenuChoices = [];
    menuInfo.AlternateChoices = [];
    menuInfo.DiningAreas = [];
    menuInfo.AlternateItems = orderInfo.AlternateItems;
    _.forEach(menuInfo.AlternateItems, (altitm) => {
      let selsub = this.state.selectedSubRecipes.filter((x) => x.ParentRecipeId === altitm.ItemId && x.isAlternateItem);
      if (selsub?.length > 0) altitm.SubItemList = selsub;
    });

    menuInfo.MenuItems = orderInfo.MenuItems;
    _.forEach(menuInfo.MenuItems, (itm) => {
      let selsub = this.state.selectedSubRecipes.filter((x) => x.ParentRecipeId === itm.ItemId && !x.isAlternateItem);
      if (selsub?.length > 0) itm.SubItemList = selsub;
    });

    if (this.props.residentId === "guest") {
      let guest = this.props.guest;
      menuInfo.Order.Guest = guest.guestName;
      menuInfo.Order.GuestBillingTypeId = guest.paymentDone;
      menuInfo.Order.GuestOfResidentId = guest.selectedResident;
      menuInfo.Order.GuestTypeId = guest.selectedGuestType;
    }
    if (orderInfo.Order.Id && orderInfo.Order.Id !== 0) {
      menuInfo.Order.Id = orderInfo.Order.Id;
    }
    menuInfo.Order.Note = this.state.note;
    menuInfo.Order.AdditionalCharges = this.state.AdditionalCharges;
    menuInfo.Order.DiningAreaId = this.state.selectedDiningArea;
    menuInfo.Order.TableName = this.state.selectedTable;
    menuInfo.Order.OutOfBuilding = this.state.outOfBuilding;
    menuInfo.Order.DeclinedMeal = this.state.declinedMeal;
    this.setState({ print: true });
    if (option === 0) {
      this.props.submitOrderV2(menuInfo, this.state.holdFlag === "true" || this.props.residentId === "guest", (response) => {
        const { success, error } = response;
        this.setState({ loading: false });
        if (success) {
          if (menuInfo?.Order?.Id !== 0) this.props.deletePending(menuInfo);

          if (this.props.permissions.indexOf("Direct Print") !== -1) {
            var content = document.getElementById("printComponent");
            var pri = document.getElementById("ifmcontentstoprint").contentWindow;
            pri.document.open();
            pri.document.write(content.innerHTML);
            pri.document.close();
            pri.focus();
            pri.print();
          } else {
            if (this.props.residentId === "guest") {
              this.props.history.push("/takeorder/guest");
            } else {
              this.props.history.push("/takeorder/residents");
            }
          }
        } else {
          this.props.showError(error);
        }
      });
    }
    else {
      menuInfo.modalItems = this.state.modalItems;
      this.props.postResidentHoldOrder(menuInfo, (response) => {
        const { error } = response;
        if (error) {
          var errorMsg = "";
          if (error === "Failed") {
            errorMsg = `Request Failed`;
          } else {
            errorMsg = error;
          }
          this.setState({
            loading: false,
          });
          this.props.showError(errorMsg);
        }
      });
      if (this.props.residentId === "guest") {
        this.props.history.push("/takeorder/guest");
      } else {
        this.props.history.push("/takeorder/residents");
      }
    }
  }

  componentDidMount() {
    const { classes } = this.props;
    this.props.getMenuV2(this.props.residentId, this.props.tableSideSetupId, (response) => {
      const { resident, error } = response;
      if (error) {
        var errorMsg = "";
        if (error === "Failed") {
          errorMsg = `Request Failed`;
        } else {
          errorMsg = error;
        }
        this.setState({
          loading: false,
        });
        this.props.showError(errorMsg);
      }
      if (resident) {
        this.props.saveMealName(resident.MealName);
        this.setState({
          directPrint: resident.DirectPrint,
          infoNotes: resident.Notes,
          infoBeverages: resident.Beverages,
          roomBed: resident.RoomBed,
        });
        let menuItems = resident.MenuChoices;
        let AlternateItems = resident.AlternateChoices;
        let orderInfo = {
          MenuItems: [],
          AlternateItems: [],
          Order: {
            Id: 0,
            Note: "",
            Guest: "",
          },
        };
        let modalItems = {
          MenuItems: [],
          AlternateItems: [],
        };
        let itemsChecked = {
          AlternateItems: {},
          MenuItems: {},
        };

        const search = this.props.history.location.search;
        const params = new URLSearchParams(search);
        this.setState({ menuChoicesWithSubRecipe: resident.MenuChoices });
        this.setState({
          alternateMenuChoicesWithSubRecipe: resident.AlternateChoices,
        });
        this.setState({ selectedSubRecipes: [] });
        this.setState({ collapseRowStatus: [] });

        let editableOrder = this.props.editableOrder ? Object.assign({}, this.props.editableOrder) : null;
        if (editableOrder) {
          this.props.saveEditableOrder(null);
          this.setState({
            guestName: editableOrder.Order.Guest,
            paymentDone: editableOrder.Order.GuestBillingTypeId,
            selectedResident: editableOrder.Order.GuestOfResidentId,
            selectedDiningArea: editableOrder.Order.DiningAreaId,
            note: editableOrder.Order.Note,
            AdditionalCharges: editableOrder.Order.AdditionalCharges,
            declinedMeal: editableOrder.Order.DeclinedMeal,
            outOfBuilding: editableOrder.Order.OutOfBuilding,
          });

          if (this.state.note) {
            this.setState({ showAdditionalCharges: true });
          }

          _.forEach(this.props.diningAreas, (item) => {
            if (item.Id === editableOrder.Order.DiningAreaId) {
              this.setState({
                tables: item.Tables,
                selectedTable: editableOrder.Order.TableName,
              });
            }
          });
          
          orderInfo.Order.Id = editableOrder.Order.Id;
          let holdedMenuItems = editableOrder.MenuItems;
          let subrec = this.state.selectedSubRecipes;
          let tmpcollpase = this.state.collapseRowStatus;

          _.forEach(holdedMenuItems, (i) => {
            _.forEach(menuItems, (mi) => {
              if (i.ItemId === mi.RecipeID) {
                if (!mi.IsAllergy) {
                  orderInfo.MenuItems.push({
                    ItemId: i.ItemId,
                    Quantity: i.Quantity,
                    GuestPrice: i.GuestPrice,
                    MenuCategory: i.MenuCategory,
                    IsSubstitution: i.IsSubstitution,
                    SubItemList: i.SubItemList,
                  });

                  //let tmpprice = 0;
                  _.forEach(i.SubItemList, (sub) => {
                    subrec.push({
                      ParentRecipeId: i.ItemId,
                      RecipeName: sub.RecipeName,
                      RecipeId: sub.RecipeId,
                      Quantity: sub.Quantity,
                      isAlternateItem: false,
                    });
                    //tmpprice = tmpprice + mi.GuestPrice * sub.Quantity;

                    let tmpcol = tmpcollpase.filter((x) => x.rowid === i.ItemId);
                    if (tmpcol.length <= 0) {
                      tmpcol.rowid = i.ItemId;
                      tmpcol.colstatus = true;
                      tmpcollpase.push(tmpcol);
                    }
                  });

                  mi.Quantity = i.Quantity;
                  modalItems.MenuItems.push(mi);
                  itemsChecked.MenuItems[mi.RecipeID] = true;
                  resident.GuestPrice = resident.GuestPrice + mi.GuestPrice * mi.Quantity; //+ tmpprice
                }
              }
            });
          });

          let holdedAlternateItems = editableOrder.AlternateItems;
          _.forEach(holdedAlternateItems, (i) => {
            _.forEach(AlternateItems, (ai) => {
              if (i.ItemId === ai.RecipeID) {
                if (!ai.IsAllergy) {
                  orderInfo.AlternateItems.push({
                    ItemId: i.ItemId,
                    Quantity: i.Quantity,
                    GuestPrice: i.GuestPrice,
                    MenuCategory: i.MenuCategory,
                    IsSubstitution: i.IsSubstitution,
                  });

                  //let tmpprice = 0;
                  _.forEach(i.SubItemList, (sub) => {
                    subrec.push({
                      ParentRecipeId: i.ItemId,
                      RecipeName: sub.RecipeName,
                      RecipeId: sub.RecipeId,
                      Quantity: sub.Quantity,
                      isAlternateItem: true,
                    });
                    //tmpprice = tmpprice + ai.GuestPrice * sub.Quantity;
                  });

                  ai.Quantity = i.Quantity;
                  modalItems.AlternateItems.push(ai);
                  itemsChecked.AlternateItems[ai.RecipeID] = true;
                  resident.GuestPrice = resident.GuestPrice + ai.GuestPrice * ai.Quantity; //+ tmpprice
                }
              }
            });
          });
        } else {
          if (this.props.permissions.indexOf("All Items Selected by Default") !== -1) {
            _.forEach(menuItems, (i) => {
              // console.log("getMenuv2():", i);
              if (!i.IsAllergy) {
                orderInfo.MenuItems.push({
                  ItemId: i.RecipeID,
                  Quantity: 1,
                  GuestPrice: i.GuestPrice,
                  MenuCategory: i.MenuCategory,
                  IsSubstitution: i.IsSubstitution,
                  SubItemList: [],
                });
                // console.log("getMenuV2():after push:", orderInfo.MenuItems);
                i.Quantity = 1;
                modalItems.MenuItems.push(i);
                itemsChecked.MenuItems[i.RecipeID] = true;
                resident.GuestPrice = resident.GuestPrice + i.GuestPrice;
              }
            });
          }
          else
            this.setState({ISAllSelected: false});

          this.GetSelectedDiningAreaTables();
        }

        var holdFlag = params.get("current");
        var fromSP = params.get("from") === "sp";
        if (holdFlag === undefined) holdFlag = "false";
        this.setState({
          loading: false,
          menuInfo: resident,
          orderInfo: orderInfo,
          itemsChecked: itemsChecked,
          modalItems: modalItems,
          holdFlag: holdFlag,
          fromSP: fromSP,
        });
        if (!editableOrder) {

          this.setState({
            selectedDiningArea: resident.Order.DiningAreaId,
            selectedTable: resident.Order.TableName,
            note: resident.Order.Note,
            declinedMeal: resident.Order.DeclinedMeal,
            outOfBuilding: resident.Order.OutOfBuilding,
          });

        }

        if (this.props.residentId === 'guest' && resident.Order.ResidentId === 0 && !editableOrder)
          this.getDiningRoomFromResident();

        var ordersubmitted = false;
        if (this.getMealbyTableSetupId(this.props.tableSideSetupId)?.OrderTaken && !fromSP) {
          ordersubmitted = true;
          this.displayOrderSubmittedAlert();
        }
        if (resident.FluidRestriction !== null && resident.FluidRestriction !== "") {
          this.setState({
            fluidRestriction: true,
          });
          this.props.setModalComponent(
            <Grid container justify="center" alignItems="center" direction="column">
              <div>Fluid Restriction</div>
              <br />
              <div>This resident has a fluid restriction!</div>
              <br />
              <div>
                <Button variant="raised" color="primary" onClick={() => this.displayFluidRestrictionAlert(ordersubmitted)} className={classes.btn}>
                  Ok
                </Button>
              </div>
            </Grid>
          );
        }
      }

    });

    this.getMealsAddOn();
  }

  getMealsAddOn = async () => {

      var addon = null;
      await this.props.GetMealAddOn(this.props.residentId, this.props.tableSideSetupId, (response) => {
        addon = response.mealsaddon;

        this.setState({
          mealsAddOn: addon
        })
      }); 
  }

  getDiningRoomFromResident = async () => {

    var gst = localStorage.getItem("guest") !== undefined ? JSON.parse(localStorage.getItem("guest")) : null;

    if (gst !== undefined && gst !== null) {

      var dareaid = null;
      await this.props.getDiningRoomFromResidentId(gst.selectedResident, (response) => {
          dareaid = response.data;
      });

      this.setState({
        selectedDiningArea: dareaid
      })

      if (this.props.guest.selectedGuestType > 1) {
        this.setState({
          selectedDiningArea: this.props.diningAreas[0].Id
        })
      }
    }

  }
  
  displayFluidRestrictionAlert = (shouldOrderAlertDisplay) => {
    this.props.handleCloseModal();

    if (shouldOrderAlertDisplay) {
      this.displayOrderSubmittedAlert();
    }
  };

  displayOrderSubmittedAlert() {
    const { classes } = this.props;
    this.props.setModalComponent(
      <Grid container justify="center" alignItems="center" direction="column">
        <div>Alert</div>
        <br />
        <div>
          <p>You have already taken one order for this person.</p>
          <p>This order will be sent in addition to the original order.</p>
          <p>If you want to reprint the original order, go to the 'Submitted' orders tab.</p>
        </div>
        <br />
        <div>
          <Button variant="raised" color="primary" onClick={() => this.props.handleCloseModal()} className={classes.btn}>
            Ok
          </Button>
        </div>
      </Grid>
    );
  }

  displaBlankDiningAreaAlert() {
    const { classes } = this.props;
    this.props.setModalComponent(
      <Grid container justify="center" alignItems="center" direction="column">
        <div>Alert</div>
        <br />
        <div>
          <p>Dining Area can not be blank.</p>
        </div>
        <br />
        <div>
          <Button variant="raised" color="primary" onClick={() => this.props.handleCloseModal()} className={classes.btn}>
            Ok
          </Button>
        </div>
      </Grid>
    );
  }

  renderServingSize(maxNum) {
    const { classes } = this.props;
    let sizes = [];
    let servingSizes = [1 / 2];
    for (let i = 0; i < maxNum; i++) {
      servingSizes.push(i + 1);
    }
    _.forEach(servingSizes, (item) => {
      sizes.push(
        <MenuItem key={item} value={item}>
          <span className={classes.generalText}>{item}</span>
        </MenuItem>
      );
    });
    return sizes;
  }

  GetSelectedDiningAreaTables = async () => {

    var arrdiningarea = null;
    await this.props.GetResidentTableSelection(this.props.residentId, (response) => {
      arrdiningarea = response.data;
    });

    if (arrdiningarea != null) 
    {
      console.log(Object.values(arrdiningarea));
      let mealname = localStorage.getItem("mealName")!=='undefined' ? JSON.parse(localStorage.getItem("mealName")) : null;
      var diningareaval = null;

      if (mealname?.includes("Breakfast"))
        diningareaval = arrdiningarea[0];
      else if (mealname?.includes("Lunch"))
        diningareaval = arrdiningarea[1];
      else if (mealname?.includes("Dinner"))
        diningareaval = arrdiningarea[2]; 

      var dinareaid = diningareaval["DiningAreaId"];
      var tabname = diningareaval["TableName"];
    }

    this.setState({
      selectedDiningArea: dinareaid,
      selectedTable: tabname == null ? "N/A" : tabname
    })
  }

  renderDiningAreas() {
    const { classes } = this.props;
    let diningAreas = [];
    if (this.state.menuInfo !== null) {
      _.forEach(this.props.diningAreas, (item) => { //   this.state.menuInfo.DiningAreas
        diningAreas.push(
          <MenuItem key={item.Id} value={item.Id}>
            <span className={classes.generalText}>{item.Name}</span>
          </MenuItem>
        );
      });
    }
    return diningAreas;
  }

  renderTables() {
    const { classes } = this.props;
    let tables = [];

    if (this.state.selectedDiningArea !== null && this.state.selectedDiningArea > 0) {
      const diningAreaIndex = this.props.diningAreas.map(e => e.Id).indexOf(this.state.selectedDiningArea);
      if (diningAreaIndex > -1) {

        if (this.props.diningAreas[diningAreaIndex].Tables.length <= 0) {
          tables.push(
            <MenuItem key={0} value={"N/A"}>
              <span className={classes.generalText}>{"N/A"}</span>
            </MenuItem>
          );
        }
        
        _.forEach(this.props.diningAreas[diningAreaIndex].Tables, (item) => {
          tables.push(
            <MenuItem key={item.Id} value={item.Name}>
              <span className={classes.generalText}>{item.Name}</span>
            </MenuItem>
          );
        });
      }
    }
    return tables;
  }

  renderSubMenuModel = (e, submenurecipe) => {
    e.stopPropagation();
    this.setState({
      currentSubRecipe: submenurecipe,
      modalSubRecipeOpen: true,
    });
  };

  renderSubMenuModelForAlternate = (e, altsubrecipe) => {
    e.stopPropagation();
    this.setState({ currentSubRecipe: altsubrecipe, modalSubRecipeOpen: true });
  };

  handlePopUpImage = (recid) => {
    this.props.getRecipeImage(recid, (response) => {
      const { resident, error } = response;
      if (error) {
        var errorMsg = "";
        if (error === "Failed") {
          errorMsg = `Request Failed`;
        } else {
          errorMsg = error;
        }
        this.setState({
          loading: false,
        });
        this.props.showError(errorMsg);
      }
      if (resident !== "") {
        this.setState({
          loading: false,
          recipeimageurl: resident,
        });

        this.modalref.toggleModal();
      }
    });
  };

  renderMenuItems() {
    let menuItems = [];
    const { classes } = this.props;

    if (this.state.menuInfo !== null) {
      _.forEach(this.state.menuInfo.MenuChoices, (item) => {
        // if (!item.HideFromMenu) {
        // console.log("renderMenuItems:item:", item);
        let orderInfo = this.state.orderInfo;
        let selectedValue = "";
        // console.log("renderMenuItems:orderInfo.MenuItems:", orderInfo.MenuItems);
        _.forEach(orderInfo.MenuItems, (i) => {
          if (i.ItemId === item.RecipeID) {
            selectedValue = i.Quantity;
          }
        });
        if (selectedValue === undefined) selectedValue = "";
        let flag = this.state.itemsChecked.MenuItems[item.RecipeID];

        let tempsubmenu = null;
        if (this.state.menuChoicesWithSubRecipe && this.state.menuChoicesWithSubRecipe.length) {
          tempsubmenu = this.state.menuChoicesWithSubRecipe.find((a) => a.RecipeID === item.RecipeID);
        }

        let colrowstat = this.state.collapseRowStatus;
        let colstat = colrowstat?.filter((x) => x.rowid === item.RecipeID);
        let tmpcolstatus = colstat.length > 0 ? colstat[0].colstatus : false;

        menuItems.push(
          <TableRow
            className={classes.toggleRow}
            style={{
              backgroundColor: flag !== undefined && flag === true ? Colors.green : "white",
            }}
            key={item.RecipeID}
            onClick={() => {
              if (!item.IsAllergy) {
                this.handleChange(item, "MenuItems", !(flag !== undefined && flag === true));
              }
            }}
          >
            {/* <TableCell>
                            <Grid>
                                <Grid className={classes.desContainer}>
                                    <span className={(flag!==undefined && flag===true) ? classes.highlightedDes : classes.generalDes}>{ item.RecipeName }</span>
                                </Grid>
                                <Grid className={classes.subItemContainer}>
                                    { item.IsAllergy && 
                                    <Grid container justify="center" alignItems="center">
                                        <Warning className={classes.warning}/>
                                        <Typography className={classes.warning}><span className={classes.generalText}>{" ALLERGY"}</span></Typography>
                                    </Grid> }
                                    <Select
                                        disabled = {!(flag!==undefined && flag===true)}
                                        value={ selectedValue }
                                        onChange={(event) => this.selectServingSize(item, 'MenuItems', event.target.value)}
                                        onClick = {event => event.stopPropagation()}
                                    >
                                        { this.renderServingSize() }
                                    </Select>
                                    <Switch checked={flag!==undefined && flag===true}/>
                                </Grid>
                            </Grid>
                        </TableCell> */}
            <TableCell style={{ width: "10px", justify: "center", padding: "0" }}>
              <Grid container justify="center" alignItems="center">
                <IconButton
                  onClick={(e) => {
                    if (colstat.length > 0) {
                      colstat[0].colstatus = !tmpcolstatus;
                      this.setState({ collapseRowStatus: colrowstat });
                    }
                    e.stopPropagation();
                  }}
                >
                  {tmpcolstatus ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Grid>
            </TableCell>
            <TableCell style={{ padding: "0" }}>
              <Grid container justify="flex-start" alignItems="flex-start">
                <Media query="(max-width: 599px)">
                  {(matches) =>
                    matches ? (
                      <span className={flag !== undefined && flag === true ? classes.highlightedDesSmall : classes.generalDesSmall}>{item.RecipeName}</span>
                    ) : (
                      <span className={flag !== undefined && flag === true ? classes.highlightedDes : classes.generalDes}>{item.RecipeName}</span>
                    )
                  }
                </Media>
              </Grid>
              {/* <span className={(flag!==undefined && flag===true) ? classes.highlightedDes : classes.generalDes}>{ item.RecipeName }</span> */}
            </TableCell>
            {item.IsAllergy && (
              <TableCell style={{ padding: "0" }}>
                <Grid container justify="center" alignItems="center">
                  <Warning className={classes.warning} />
                  <Typography className={classes.warning}>
                    <span className={classes.generalText}>{" ALLERGY"}</span>
                  </Typography>
                </Grid>
              </TableCell>
            )}
            <TableCell style={{ padding: "0" }}>
              <Grid container justify="flex-end" alignItems="center">
                {flag !== undefined && flag === true && tempsubmenu && tempsubmenu.HasSubRecipes && (
                  <HasSubRecipesIcon style={{ color: "#2F4F4F", marginRight: "5px" }} onClick={(e) => this.renderSubMenuModel(e, tempsubmenu)} />
                )}

                {item.ImageAvailable && <CameraAltIcon style={{ color: "#2F4F4F", marginRight: "5px" }} onClick={() => this.handlePopUpImage(item.RecipeID)} />}
                {item.ImageAvailable && <ModalImage ref={(c) => (this.modalref = c)} large={this.state.recipeimageurl} hideDownload={true} />}

                {item.IsHeartHealthy && <Favorite style={{ color: "#ab403d", marginRight: "5px" }} />}
                {item.IsDiabeticChoice && (
                  <img
                    alt=""
                    src={diamond}
                    style={{
                      width: "27px",
                      height: "28px",
                      marginRight: "5px",
                    }}
                  />
                )}
                <Select
                  style={{ marginRight: "5px" }}
                  disabled={!(flag !== undefined && flag === true)}
                  value={selectedValue}
                  onChange={(event) => this.selectServingSize(item, "MenuItems", event.target.value)}
                  onClick={(event) => event.stopPropagation()}
                >
                  {this.renderServingSize(item.MaxNumberOfServings)}
                </Select>
              </Grid>
            </TableCell>
            {/* <TableCell>
                            <Switch checked={flag!==undefined && flag===true}/>
                        </TableCell> */}
          </TableRow>
        );
        let subrec = this.state.selectedSubRecipes?.filter((x) => x?.ParentRecipeId === item.RecipeID && !x?.isAlternateItem);

        _.forEach(subrec, (sr) => {
          menuItems.push(
            <TableRow class="hide-table-padding">
              <TableCell style={{ backgroundColor: Colors.green, padding: 0 }} colSpan={4}>
                <Collapse in={tmpcolstatus} timeout="auto" unmountOnExit>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Grid>
                            <span style={{ marginLeft: "100px" }} className={classes.highlightedDes}>
                              {sr.RecipeName}
                            </span>
                          </Grid>
                        </TableCell>
                        <TableCell style={{ paddingRight: "2" }}>
                          <Grid container justify="flex-end" alignItems="center">
                            <span className={classes.highlightedDes}>{sr.Quantity}</span>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Collapse>
              </TableCell>
            </TableRow>
          );
        });
        // }
      });
    }
    return menuItems;
  }

  renderAlternateItems() {
    const { classes } = this.props;
    let alternateComponents = [];
    if (this.state.menuInfo !== null) {
      let itemsByGroup = _.groupBy(this.state.menuInfo.AlternateChoices, _.property("MenuCategory"));
      for (let key in itemsByGroup) {
        if (itemsByGroup.hasOwnProperty(key)) {
          alternateComponents.push(
            <ExpansionPanel key={key} defaultExpanded={false}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  <span className={classes.generalText}>{key}</span>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Table>
                  <TableBody>{this.renderListItems(itemsByGroup[key])}</TableBody>
                </Table>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        }
      }
    }
    return alternateComponents;
  }

  renderListItems(items) {
    const { classes } = this.props;
    let list = [];
    _.forEach(items, (item) => {
      let flag = this.state.itemsChecked.AlternateItems[item.RecipeID];
      let orderInfo = this.state.orderInfo;
      let selectedValue = "";
      _.forEach(orderInfo.AlternateItems, (i) => {
        if (i.ItemId === item.RecipeID) {
          selectedValue = i.Quantity;
        }
      });
      if (selectedValue === undefined) selectedValue = "";
      list.push(
        <TableRow
          className={classes.toggleRow}
          key={item.RecipeID}
          style={{
            backgroundColor: flag !== undefined && flag === true ? Colors.green : "white",
          }}
          onClick={() => {
            if (!item.IsAllergy) {
              this.handleChange(item, "AlternateItems", !(flag !== undefined && flag === true));
            }
          }}
        >
          <TableCell style={{ padding: "0" }}>
            <span className={flag !== undefined && flag === true ? classes.highlightedDes : classes.generalDes}>{item.RecipeName}</span>
          </TableCell>
          <TableCell style={{ padding: "0" }}>
            {item.IsAllergy && (
              <Grid container justify="center" alignItems="center">
                <Warning className={classes.warning} />
                <Typography className={classes.warning}>
                  <span className={classes.generalText}>{" ALLERGY"}</span>
                </Typography>
              </Grid>
            )}
          </TableCell>
          <TableCell style={{ padding: "0" }}>
            <Grid container justify="flex-end" alignItems="center">
              {flag !== undefined && flag === true && item.HasSubRecipes && (
                <HasSubRecipesIcon style={{ color: "#2F4F4F", marginRight: "5px" }} onClick={(e) => this.renderSubMenuModelForAlternate(e, item)} />
              )}
              {item.ImageAvailable && <CameraAltIcon style={{ color: "#2F4F4F", marginRight: "5px" }} onClick={() => this.handlePopUpImage(item.RecipeID)}/>}
              {item.ImageAvailable && <ModalImage ref={(c) => (this.modalref = c)} large={this.state.recipeimageurl} hideDownload={true} />}
              {item.IsHeartHealthy && <Favorite style={{ color: "#ab403d", marginRight: "5px" }} />}
              {item.IsDiabeticChoice && <img alt="" src={diamond} style={{ width: "27px", height: "28px", marginRight: "5px" }} />}
              <Select
                disabled={!(flag !== undefined && flag === true)}
                value={selectedValue}
                onChange={(event) => this.selectServingSize(item, "AlternateItems", event.target.value)}
                onClick={(event) => event.stopPropagation()}
              >
                {this.renderServingSize(item.MaxNumberOfServings)}
              </Select>
            </Grid>
          </TableCell>
          {/* <TableCell>
                        <Switch checked={flag!==undefined && flag===true}/>
                    </TableCell> */}
        </TableRow>
      );

      let subrec = this.state.selectedSubRecipes?.filter((x) => x?.ParentRecipeId === item.RecipeID && x?.isAlternateItem);

      _.forEach(subrec, (sr) => {
        list.push(
          <TableRow style={{ backgroundColor: Colors.green }}>
            <TableCell>
              <Grid>
                <span style={{ marginLeft: "100px" }} className={classes.highlightedDes}>
                  {sr.RecipeName}
                </span>
              </Grid>
            </TableCell>
            <TableCell colSpan={3}>
              <Grid container justify="flex-end" alignItems="center">
                <span className={classes.highlightedDes}>{sr.Quantity}</span>
              </Grid>
            </TableCell>
          </TableRow>
        );
      });
    });
    return list;
  }

  renderConfirmItems(option) {
    let modalItems = this.state.modalItems;
    const { classes } = this.props;
    let menuList = [];
    let price = 0;

    _.forEach(modalItems.MenuItems, (item) => {
      menuList.push(
        <TableRow key={item.RecipeID}>
          <TableCell>
            <span className={classes.generalText}>{item.RecipeName}</span>
          </TableCell>
          <TableCell>
            <span className={classes.generalText}>{item.Quantity}</span>
          </TableCell>
        </TableRow>
      );

      let subrec = this.state.selectedSubRecipes?.filter((x) => x?.ParentRecipeId === item.RecipeID && !x?.isAlternateItem);
      //let subitemtotalprice = 0;
      _.forEach(subrec, (sr) => {
        menuList.push(
          <TableRow key={sr.RecipeID}>
            <TableCell>
              <span style={{ marginLeft: "30px" }} className={classes.generalText}>
                {sr.RecipeName}
              </span>
            </TableCell>
            <TableCell>
              <span className={classes.generalText}>{sr.Quantity}</span>
            </TableCell>
          </TableRow>
        );
        //subitemtotalprice = subitemtotalprice + sr.Quantity * item.GuestPrice;
      });
      price = price + item.Quantity * item.GuestPrice; //+ subitemtotalprice
    });
    let alternateList = [];
    _.forEach(modalItems.AlternateItems, (item) => {
      alternateList.push(
        <TableRow key={item.RecipeID}>
          <TableCell>
            <span className={classes.generalText}>{item.RecipeName}</span>
          </TableCell>
          <TableCell>
            <span className={classes.generalText}>{item.Quantity}</span>
          </TableCell>
        </TableRow>
      );

      let subrec = this.state.selectedSubRecipes?.filter((x) => x?.ParentRecipeId === item.RecipeID && x?.isAlternateItem);
      //let subitemtotalprice = 0;
      _.forEach(subrec, (sr) => {
        alternateList.push(
          <TableRow key={sr.RecipeID}>
            <TableCell>
              <span style={{ marginLeft: "30px" }} className={classes.generalText}>
                {sr.RecipeName}
              </span>
            </TableCell>
            <TableCell>
              <span className={classes.generalText}>{sr.Quantity}</span>
            </TableCell>
          </TableRow>
        );
        //subitemtotalprice = subitemtotalprice + sr.Quantity * item.GuestPrice;
      });

      price = price + item.Quantity * item.GuestPrice; //+ subitemtotalprice
    });
    return (
      <Grid container justify="center" alignItems="center" direction="column">
        {/* {menuList.length === 0 && alternateList.length === 0 ? 
                    "You have not ordered any items." : "Are these correct?"
                } */}
        Are you sure?
        {menuList.length > 0 && (
          <Card className={classes.modalItemContainer}>
            <CardContent>
              <Typography variant="headline" component="h2">
                Menu Items
              </Typography>
              <Table>
                <TableBody>{menuList}</TableBody>
              </Table>
            </CardContent>
          </Card>
        )}
        {alternateList.length > 0 && (
          <Card className={classes.modalItemContainer}>
            <CardContent>
              <Typography variant="headline" component="h2">
                Alternate Items
              </Typography>
              <Table>
                <TableBody>{alternateList}</TableBody>
              </Table>
            </CardContent>
          </Card>
        )}
        {this.state.note && this.state.note !== "N/A" && this.state.note !== "" && (
          <Card className={classes.modalItemContainer}>
            <CardContent>
              <Typography variant="headline" component="h2">
                Note
              </Typography>
              <Typography>
                <Grid container justify="space-between">
                  <span className={classes.generalText}>{this.state.note}</span>
                  <span className={classes.generalText}>${this.state.AdditionalCharges.toFixed(2)}</span>
                </Grid>
              </Typography>
            </CardContent>
          </Card>
        )}
        <Card className={classes.modalItemContainer}>
          <CardContent className={classes.guestPrice}>
            <Typography variant="headline" component="h2">
              Meal Price
            </Typography>
            <Typography variant="headline" component="h2" style={{ marginRight: "40px", marginLeft: "10px" }}>
              ${(price + this.state.AdditionalCharges).toFixed(2)}
            </Typography>
          </CardContent>
        </Card>
        <Grid container className={classes.modalItemContainer} justify="center">
          {/* {(menuList.length !== 0 || alternateList.length !== 0) && 
                    <Button
                        variant="raised"
                        color="primary"
                        onClick={()=>this.submitOrder()}
                        className={classes.btn}
                    >
                        Submit
                    </Button>
                } */}
          {option === 0 && (
            <Button variant="raised" color="primary" onClick={() => this.submitOrder(0)} className={classes.btn}>
              Submit
            </Button>
          )}
          {option === 1 && (
            <Button variant="raised" color="primary" onClick={() => this.submitOrder(1)} className={classes.btn}>
              Hold
            </Button>
          )}

          <Button
            variant="raised"
            color="primary"
            onClick={() => {
              CustomModal.removeDisplayScroll();
              this.props.handleCloseModal();
            }}
            className={classes.btn}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    );
  }

  renderPrintItems() {
    let menuInfo = this.state.modalItems;
    let menuList = [];
    _.forEach(menuInfo.MenuItems, (item) => {
      let quantity;
      if (item.Quantity === 0.5) {
        quantity = "1/2";
      } else {
        quantity = item.Quantity;
      }
      menuList.push(
        <div style={printStyles.divSpace} key={item.RecipeName}>
          <span>{`${quantity} - ${item.PortionSize} ${item.RecipeName}`}</span>
        </div>
      );
    });
    _.forEach(menuInfo.AlternateItems, (item) => {
      let quantity;
      if (item.Quantity === 0.5) {
        quantity = "1/2";
      } else {
        quantity = item.Quantity;
      }
      menuList.push(
        <div style={printStyles.divSpace}>
          <span>{`${quantity} - ${item.PortionSize} ${item.RecipeName}`}</span>
        </div>
      );
    });
    return menuList;
  }

  formatDate() {
    var newDate = new Date();

    var sMonth = this.padValue(newDate.getMonth() + 1);
    var sDay = this.padValue(newDate.getDate());
    var sYear = newDate.getFullYear();
    var sHour = newDate.getHours();
    var sMinute = this.padValue(newDate.getMinutes());
    var sAMPM = "AM";

    var iHourCheck = parseInt(sHour.toString(), 10);

    if (iHourCheck > 12) {
      sAMPM = "PM";
      sHour = iHourCheck - 12;
    } else if (iHourCheck === 0) {
      sHour = "12";
    }

    sHour = this.padValue(sHour);

    return sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
  }

  padValue(value) {
    return value < 10 ? "0" + value : value;
  }

  getResidentById(id) {
    let residents = this.props.residents;
    let result = "";

    if (residents !== undefined)
    {
      residents.forEach((resident) => {
        if (resident.Id === id) {
          result = resident;
        }
      });
    }
    return result;
  }

  getMealbyTableSetupId(id) {
    let actmeals = this.props.activemeals?.Days;
    let result = "";
    actmeals.forEach((d) => {
      let ml = d.Meals;
      ml.forEach((m) => {
        let tmpid = m.SetupId?.toString();
        if (tmpid === id) {
          result = m;
        }
      });
    });
    return result;
  }

  getDiningAreaById(id) {
    let areas = this.props.diningAreas;
    let result = "";
    areas.forEach((area) => {
      if (area.Id === id) {
        result = area.Name;
      }
    });
    return result;
  }

  currencyFormatter = (event, maskedvalue, floatvalue) => {
    this.setState({ AdditionalCharges: floatvalue });
  };

  checkForDietOtherSeperator(menuInfo) {
    if (
      (menuInfo.DietName !== "N/A" &&
        menuInfo.DietName &&
        menuInfo.DietTextureName !== "N/A" &&
        menuInfo.DietTextureName &&
        menuInfo.DietOther !== "N/A" &&
        menuInfo.DietOther) ||
      (menuInfo.DietName && !menuInfo.DietTextureName && menuInfo.DietOther) ||
      (!menuInfo.DietName && menuInfo.DietTextureName && menuInfo.DietOther)
    ) {
      return true;
    }

    return false;
  }

  checkForDietNameSeperator(menuInfo) {
    if (menuInfo.DietName !== "N/A" && menuInfo.DietName && menuInfo.DietTextureName !== "N/A" && menuInfo.DietTextureName) {
      return true;
    }

    return false;
  }

  subRecipesSelected = (selsubitems) => {
    let isalternate = this.state.currentSubRecipe?.IsAlternateRecipe;

    if (!isalternate) {
      let rowid = this.state.currentSubRecipe?.RecipeID;
      let tmpcollpase = this.state.collapseRowStatus;
      let totsubrecforselcteditem = selsubitems?.filter((x) => x.ParentRecipeId === rowid)?.length;

      if (totsubrecforselcteditem > 0) {
        let tmpcol = tmpcollpase.filter((x) => x.rowid === rowid);
        if (tmpcol.length > 0) tmpcol[0].colstatus = true;
        else {
          tmpcol.rowid = rowid;
          tmpcol.colstatus = true;
          tmpcollpase.push(tmpcol);
        }
      } else {
        tmpcollpase = tmpcollpase?.filter((item) => item.rowid !== rowid);
      }
      this.setState({ collapseRowStatus: tmpcollpase });
    }
    this.setState({ selectedSubRecipes: Object.assign([], selsubitems) });
    this.setState({ modalSubRecipeOpen: false });
  };

  SelectionChange() {
    
    let modalItems = this.state.menuInfo.MenuChoices;

    _.forEach(modalItems, (item) => {
      
      if (!item.IsAllergy) 
      {
        this.state.ISAllSelected ? 
        this.handleChange(item, "MenuItems", false) 
        : this.handleChange(item, "MenuItems", true);
      }
    });

    this.state.ISAllSelected ? 
    this.setState({ ISAllSelected: false })
      : this.setState({ ISAllSelected: true });
  }

  render() {
    const { menuInfo } = this.state;
    const { classes } = this.props;

    const selbuttontext = this.state.ISAllSelected ? "Unselect All" : "Select All";

    let maddon = '';
    if (this.state.mealsAddOn != null)
      maddon = this.state.mealsAddOn.join(';');

    return (
      <div>
        <iframe
          id="ifmcontentstoprint"
          title="orderprint"
          style={{
            height: "0px",
            width: "0px",
            position: "absolute",
            display: "none",
          }}
        ></iframe>
        {this.state.loading ? (
          <LoadingIndicator />
        ) : (
          <div className="paperContent customScrollbar">
            <div>
              <Card>
                <CardContent>
                  <Typography variant="headline" component="h2">
                    {menuInfo && menuInfo.ResidentName}
                  </Typography>
                  <Table>
                    <TableBody>
                      {menuInfo &&
                        ((menuInfo.DietName !== "N/A" && menuInfo.DietName !== "") ||
                          (menuInfo.DietTextureName !== "N/A" && menuInfo.DietTextureName !== "") ||
                          (menuInfo.DietOther !== "N/A" && menuInfo.DietOther !== "")) && (
                          <TableRow>
                            <TableCell>
                              <span className={classes.generalText}>Diet</span>
                            </TableCell>
                            <TableCell>
                              <span className={classes.generalText}>{menuInfo && menuInfo.DietName !== "N/A" && menuInfo.DietName}</span>
                              <span className={classes.generalText}>{this.checkForDietNameSeperator(menuInfo) && `/`}</span>
                              <span className={classes.generalText}>
                                {menuInfo.DietTextureName !== "" && menuInfo.DietTextureName !== "Regular" && `${menuInfo.DietTextureName}`}
                              </span>
                              <span className={classes.generalText}>{this.checkForDietOtherSeperator(menuInfo) && `/`}</span>
                              <span className={classes.generalText}>{menuInfo.DietOther !== "" && `${menuInfo.DietOther}`}</span>{" "}
                            </TableCell>
                          </TableRow>
                        )}
                      {menuInfo && menuInfo.LiquidConsistency !== "N/A" && menuInfo.LiquidConsistency !== "" && (
                        <TableRow>
                          <TableCell>
                            <span className={classes.generalText}>Liquid Consistency</span>
                          </TableCell>
                          <TableCell>
                            <span className={classes.generalText}>{menuInfo && menuInfo.LiquidConsistency !== "N/A" && menuInfo.LiquidConsistency}</span>
                          </TableCell>
                        </TableRow>
                      )}
                      {menuInfo && menuInfo.FluidRestriction !== "N/A" && menuInfo.FluidRestriction !== "" && (
                        <TableRow>
                          <TableCell>
                            <span className={classes.generalText}>Fluid Restriction</span>
                          </TableCell>
                          <TableCell>
                            <span className={classes.generalText}>{menuInfo && menuInfo.FluidRestriction !== "N/A" && menuInfo.FluidRestriction}</span>
                          </TableCell>
                        </TableRow>
                      )}
                      {menuInfo && menuInfo.Allergies !== "N/A" && menuInfo.Allergies !== "" && (
                        <TableRow>
                          <TableCell>
                            <span className={classes.generalText}>Allergies</span>
                          </TableCell>
                          <TableCell>
                            <span className={classes.generalText}>{menuInfo && menuInfo.Allergies !== "N/A" && menuInfo.Allergies}</span>
                          </TableCell>
                        </TableRow>
                      )}
                      {menuInfo && menuInfo.Dislikes !== "N/A" && menuInfo.Dislikes !== "" && (
                        <TableRow>
                          <TableCell>
                            <span className={classes.generalText}>Dislikes</span>
                          </TableCell>
                          <TableCell>
                            <span className={classes.generalText}>{menuInfo && menuInfo.Dislikes !== "N/A" && menuInfo.Dislikes}</span>
                          </TableCell>
                        </TableRow>
                      )}
                      {menuInfo && menuInfo.AdaptiveEquipment !== "N/A" && menuInfo.AdaptiveEquipment !== "" && (
                        <TableRow>
                          <TableCell>
                            <span className={classes.generalText}>Adaptive Equipment</span>
                          </TableCell>
                          <TableCell>
                            <span className={classes.generalText}>{menuInfo && menuInfo.AdaptiveEquipment !== "N/A" && menuInfo.AdaptiveEquipment}</span>
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell>
                          <span className={classes.generalText}>Meal Price</span>
                        </TableCell>
                        <TableCell>
                          <span className={classes.generalText}>
                            {menuInfo && menuInfo.GuestPrice !== "N/A" && "$" + Number(menuInfo.GuestPrice).toFixed(2)}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span className={classes.generalText}>Dining Area</span>
                        </TableCell>
                        <TableCell>
                          <Select value={this.state.selectedDiningArea} onChange={(event) => this.selectTables(event)}>
                            {this.renderDiningAreas()}
                          </Select>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span className={classes.generalText}>Table</span>
                        </TableCell>
                        <TableCell>
                            <Select value={this.state.selectedTable} onChange={(event) => this.selectTable(event)}>
                              {this.renderTables()}
                            </Select>
                        </TableCell>
                      </TableRow>
                      {this.props.residentId === "guest" && <TableRow>
                        <TableCell>
                          <span className={classes.generalText}>Guest Type</span>
                        </TableCell>
                        <TableCell>
                            <span className={classes.generalText}>
                              {this.props.guest?.selectedGuestType && GuestType[this.props.guest.selectedGuestType]}
                            </span>
                        </TableCell>
                      </TableRow> }
                      {menuInfo && this.state.mealsAddOn?.length > 0 && (
                        <TableRow>
                          <TableCell>
                            <span className={classes.generalText}>Meals AddOn</span>
                          </TableCell>
                          <TableCell>
                            <span className={classes.generalText}>{maddon}</span>
                          </TableCell>
                        </TableRow>
                      )}
                      {menuInfo && this.state.infoNotes && this.state.infoNotes.length > 0 && (
                        <TableRow>
                          <TableCell>
                            <span className={classes.generalText}>Notes</span>
                          </TableCell>
                          <TableCell>
                            {this.state.infoNotes.map((item) => {
                              return <div>{item}</div>;
                            })}
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.outOfBuilding}
                                onChange={() => {
                                  let { orderInfo, modalItems } = this.state;
                                  if (!this.state.outOfBuilding) {
                                    orderInfo.MenuItems = [];
                                    orderInfo.AlternateItems = [];
                                    modalItems.MenuItems = [];
                                    modalItems.AlternateItems = [];
                                    this.setState(
                                      {
                                        outOfBuilding: !this.state.outOfBuilding,
                                        declinedMeal: false,
                                        itemsChecked: {
                                          AlternateItems: {},
                                          MenuItems: {},
                                        },
                                        orderInfo: orderInfo,
                                        modalItems: modalItems,
                                      },
                                      () => console.log("change", this.state)
                                    );
                                  } else {
                                    this.setState({
                                      outOfBuilding: !this.state.outOfBuilding,
                                    });
                                  }
                                }}
                              />
                            }
                            label={<span className={classes.generalText}>Out of Building</span>}
                          />
                        </TableCell>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.declinedMeal}
                                onChange={() => {
                                  let { orderInfo, modalItems } = this.state;
                                  if (!this.state.declinedMeal) {
                                    orderInfo.MenuItems = [];
                                    orderInfo.AlternateItems = [];
                                    modalItems.MenuItems = [];
                                    modalItems.AlternateItems = [];
                                    this.setState({
                                      declinedMeal: !this.state.declinedMeal,
                                      outOfBuilding: false,
                                      itemsChecked: {
                                        AlternateItems: {},
                                        MenuItems: {},
                                      },
                                      orderInfo: orderInfo,
                                      modalItems: modalItems,
                                    });
                                  } else {
                                    this.setState({
                                      declinedMeal: !this.state.declinedMeal,
                                    });
                                  }
                                }}
                              />
                            }
                            label={<span className={classes.generalText}>Declined Meal</span>}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
              <Card className={classes.menuContainer}>
                <CardContent>
                  <div style={{display:"grid",  gridTemplateColumns:"3fr 1fr"}}>
                    <Typography variant="headline" component="h2">
                      Menu Items
                    </Typography>
                    <Button
                        variant="raised"
                        color="primary"
                        className={classes.btn}
                        onClick={() => this.SelectionChange()}
                    >
                          {selbuttontext}
                    </Button>
                  </div>
                  <Table>
                    <TableBody>
                      {this.renderMenuItems()}
                      {SubRecipe.setDisplayScroll()}
                      <SubRecipe
                        open={this.state.modalSubRecipeOpen}
                        handleClose={this.handleCloseModal}
                        SubRecipesItem={this.state.currentSubRecipe}
                        SelectedSubRecipeItems={this.subRecipesSelected}
                        InitialSelectedSubRecipe={this.state.selectedSubRecipes}
                      />
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
              <Card className={classes.menuContainer}>
                <CardContent className={classes.alternateContainer}>
                  <Typography variant="headline" component="h2" className={classes.alternateTitle}>
                    Alternate Items
                  </Typography>
                  {this.renderAlternateItems()}
                  {/* <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Desserts</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Typography>Other stuff goes here.</Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel> */}
                </CardContent>
              </Card>
              <Card className={classes.menuContainer}>
                <CardContent>
                  <Typography variant="headline" component="h2" className={classes.alternateTitle}>
                    Note
                  </Typography>

                  <TextField
                    id="textarea"
                    multiline
                    rows="4"
                    className={classes.note}
                    InputProps={{ disableUnderline: true }}
                    value={this.state.note}
                    onChange={(event) => {
                      if (!event.target.value) {
                        this.setState({ AdditionalCharges: 0.0 });
                        this.setState({ showAdditionalCharges: false });
                      }

                      this.setState({
                        note: event.target.value,
                      });
                    }}
                  />

                  <Grid container alignItems="center" justify="space-between">
                    <span>
                      <Button
                        disabled={!this.state.note}
                        variant="raised"
                        color="primary"
                        className={classes.btn}
                        onClick={() => this.setState({ showAdditionalCharges: true })}
                      >
                        Additional Charges
                      </Button>
                    </span>

                    <span>
                      {this.state.showAdditionalCharges && (
                        <CurrencyInput
                          prefix="$"
                          autoFocus="true"
                          id="additionalamoutinput"
                          className={classes.note}
                          value={this.state.AdditionalCharges}
                          onChangeEvent={this.currencyFormatter}
                        />
                      )}
                    </span>
                  </Grid>
                </CardContent>
              </Card>
              <Grid container justify="center" alignItems="center" className={classes.btnContainer}>
                {(this.state.holdFlag === "true" || this.props.residentId === "guest") && !this.state.fromSP && (
                  <Button
                    variant="raised"
                    color="primary"
                    className={classes.btn}
                    onClick={() => {

                      if (this.state.selectedDiningArea === null || this.state.selectedDiningArea === 0) {
                        this.displaBlankDiningAreaAlert();
                        return;
                      }

                      CustomModal.setDisplayScroll();
                      this.props.setModalComponent(
                        <Grid container justify="center" alignItems="center" direction="column">
                          {this.renderConfirmItems(1)}
                        </Grid>
                      );
                    }}
                  >
                    Hold
                  </Button>
                )}

                <Button
                  variant="raised"
                  color="primary"
                  className={classes.btn}
                  onClick={() => {

                    if (this.state.selectedDiningArea === null || this.state.selectedDiningArea === 0) {
                      this.displaBlankDiningAreaAlert();
                      return;
                    }

                    CustomModal.setDisplayScroll();
                    this.props.setModalComponent(
                      <Grid container justify="center" alignItems="center" direction="column">
                        {this.renderConfirmItems(0)}
                      </Grid>
                    );
                  }}
                >
                  {this.state.fromSP ? "Submit/Reprint" : "Submit"}
                </Button>
              </Grid>
              {/* {this.state.print &&  */}
              <div id="printComponent" style={{ position: "absolute", top: "-9999px" }}>
                <div style={{ padding: "10px 5px 5px 5px", fontSize: "15px" }}>
                  {this.props.residentId === "guest" ? (
                    <div
                      style={{
                        fontSize: "23px",
                        fontWeight: "bold",
                        paddingBottom: "5px",
                        paddingTop: "5px",
                      }}
                    >
                      {menuInfo.Order.Guest}
                    </div>
                  ) : (
                    <div
                      style={{
                        fontSize: "23px",
                        fontWeight: "bold",
                        paddingBottom: "5px",
                        paddingTop: "5px",
                      }}
                    >
                      {this.getResidentById(menuInfo.Order.ResidentId).Name}
                    </div>
                  )}

                  {this.getResidentById(menuInfo.Order.GuestOfResidentId) !== "" && (
                    <div style={printStyles.divSpace}>Guest/Employee: {this.getResidentById(menuInfo.Order.GuestOfResidentId).Name}</div>
                  )}
                  {this.props.residentId === "guest" && (
                    <div style={printStyles.divSpace}>
                      Payment Status:{" "}
                      {menuInfo.Order.GuestBillingTypeId === "0"
                        ? "No Charge"
                        : menuInfo.Order.GuestBillingTypeId === "1"
                        ? "Payment Received"
                        : "Bill to Resident"}
                    </div>
                  )}
                  <div
                    style={{
                      borderBottom: "2px solid",
                      paddingBottom: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    {this.getDiningAreaById(menuInfo.Order.DiningAreaId) !== "" && (
                      <div style={printStyles.generalInfo}>
                        <div style={printStyles.rightText}>Dining Room: &nbsp;</div>
                        {this.getDiningAreaById(menuInfo.Order.DiningAreaId)}
                      </div>
                    )}
                    {menuInfo && menuInfo.Order.TableName && menuInfo.Order.TableName !== "N/A" && menuInfo.Order.TableName !== "" && (
                      <div style={printStyles.generalInfo}>
                        <div style={printStyles.rightText}>Table: &nbsp;</div>
                        {menuInfo.Order.TableName}
                      </div>
                    )}
                    {menuInfo &&
                      ((menuInfo.DietName !== "N/A" && menuInfo.DietName !== "") ||
                        (menuInfo.DietTextureName !== "N/A" && menuInfo.DietTextureName !== "") ||
                        (menuInfo.DietOther !== "N/A" && menuInfo.DietOther !== "")) && (
                        <div style={printStyles.generalInfo}>
                          <div style={printStyles.rightText}>Diet: &nbsp;</div>
                          {menuInfo.DietName}
                          <span className={classes.generalText}>
                            {this.checkForDietNameSeperator(menuInfo) && `/`}
                            {menuInfo.DietTextureName !== "" && menuInfo.DietTextureName !== "Regular" && `${menuInfo.DietTextureName}`}
                            {this.checkForDietOtherSeperator(menuInfo) && `/`}
                            {menuInfo.DietOther !== "" && `${menuInfo.DietOther}`}
                          </span>
                        </div>
                      )}
                    {this.props.residentId !== "guest" && this.state.roomBed && this.state.roomBed !== "" && this.state.roomBed !== "N/A" && (
                      <div style={printStyles.generalInfo}>
                        <div style={printStyles.rightText}>Room Number: &nbsp;</div>
                        {this.state.roomBed}
                      </div>
                    )}
                    {this.props.permissions.indexOf("Print MealCard Notes") !== -1 && menuInfo && this.state.infoNotes && this.state.infoNotes.length > 0 && (
                      <div style={printStyles.generalInfo}>
                        <div style={printStyles.rightText}>Notes: &nbsp;</div>
                        {this.state.infoNotes.map((item) => {
                          if (this.state.infoNotes.indexOf(item) === this.state.infoNotes.length - 1) {
                            return item;
                          } else {
                            return `${item}, `;
                          }
                        })}
                      </div>
                    )}
                    {/* { menuInfo && menuInfo.DietTextureName!=="N/A" && menuInfo.DietTextureName!=="" && 
                                            <div style={printStyles.generalInfo}>
                                                <div style={printStyles.rightText}>
                                                    Diet Texture: &nbsp;
                                                </div> 
                                                {menuInfo.DietTextureName}
                                            </div>
                                        } */}
                    {/*menuInfo && menuInfo.DietOther !== "N/A" && menuInfo.DietOther !== "" &&
                                            <div style={printStyles.generalInfo}>
                                                <div style={printStyles.rightText}>
                                                    Diet Other:&nbsp;
                                                </div>
                                                {menuInfo.DietOther}
                                            </div>*/}
                    {menuInfo && menuInfo.FluidRestriction !== "N/A" && menuInfo.FluidRestriction !== "" && (
                      <div style={printStyles.generalInfo}>
                        <div style={printStyles.rightText}>Restriction: &nbsp;</div>
                        {menuInfo.FluidRestriction}
                      </div>
                    )}
                    {menuInfo && menuInfo.AdaptiveEquipment !== "N/A" && menuInfo.AdaptiveEquipment !== "" && (
                      <div style={printStyles.generalInfo}>
                        <div style={printStyles.rightText}>Adaptive Equip: &nbsp;</div>
                        {menuInfo.AdaptiveEquipment}
                      </div>
                    )}
                  </div>

                  {menuInfo && menuInfo.Allergies !== "N/A" && menuInfo.Allergies !== "" && (
                    <div style={printStyles.divSpace}>Allergies: &nbsp;{menuInfo.Allergies}</div>
                  )}
                  {this.renderPrintItems()}
                  {menuInfo && menuInfo.Order.Note !== "N/A" && menuInfo.Order.Note !== "" && (
                    <div style={printStyles.divSpace}>Note: &nbsp;{menuInfo.Order.Note}</div>
                  )}
                  <div
                    style={{
                      borderTop: "2px solid",
                      marginTop: "5px",
                      paddingBottom: "5px",
                      paddingTop: "5px",
                      fontSize: "10px",
                    }}
                  >
                    {`Order Taken: ${this.formatDate()}, by ${this.props.email}`}
                  </div>
                </div>
              </div>
              {/* } */}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    activemeals: state.orderReducer.activemeals,
    diningAreas: state.authReducer.userData.diningAreas,
    residents: state.orderReducer.residents,
    guest: state.orderReducer.guest,
    email: state.authReducer.email,
    permissions: state.authReducer.userData.permissions,
    holdedOrderArray: state.orderReducer.holdedOrderArray,
    submittedOrderArray: state.orderReducer.submittedOrderArray,
    editableOrder: state.orderReducer.editableOrder,
  }),
  {
    ...orderActions,
  }
)(withStyles(styles)(Order));
