import * as actionTypes from './actionTypes';
import request from 'utils/request';
import errorHandler from 'utils/error';

export function getMeals(callback) {
  return (dispatch) => {
    Promise.all([
      request({
        url: `/api/tableside/AvailableMeals`,
        method: 'Get'
      }), 
      request({
        url: `/api/tableside/PendingMeals`,
        method: 'Get'
      }),
    ]).then( response => {
      console.log("res", response)
      dispatch({ 
        type: actionTypes.GET_MEALS, 
        payload: { 
          availableMeals: response[0].data,
          pendingMeals: response[1].data
        } 
      });
      callback({
        result: { 
          availableMeals: response[0].data,
          pendingMeals: response[1].data
        }
      })
    })
    .catch(err => {
      callback({
        error: errorHandler(err)
      })
    })
  };
}

export function createMeal(mealInfo, callback) {
  return (dispatch) => {
    request({
        url: '/api/tableside/CreateMeal',
        method: 'Post',
        data: mealInfo
    })
      .then((res) => {
        console.log("res", res)
        callback({
            success: "success"
        });

      })
      .catch((error) => {
        console.log("error", error)

        callback({
            error: errorHandler(error)
        })
      })
  };
}

export function deleteMeal(Id, callback) {
  return (dispatch) => {
    request({
        url: '/api/tableside/DeleteMeal',
        method: 'Post',
        data: {Id: Id}
    })
      .then((res) => {
        console.log("res", res)
        callback({
            success: "success"
        });

      })
      .catch((error) => {
        console.log("error", error)

        callback({
            error: errorHandler(error)
        })
      })
  };
}