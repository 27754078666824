import { ERR_STATUS_500_MSG } from "../const";
export default (error) => {
    if (error.response && error.response.status && error.response.status === 500) {
        return ERR_STATUS_500_MSG
    } else {
        if (error.response === undefined){
            return '';
        }
        else if (error.response) {
            return 'Failed';
        } else if (error.request) {
            return 'Server can not be reached.  Please check your wifi connection';
        } else {
            return 'Something went wrong. Please check your network connection';
        }
    }


};