import React from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    
})

class Record extends React.Component {

    render() {
        return (
            <div>
                This functionality is still in development. 
            </div>
        );
    }
}

export default connect(state => ({
    
}), {
    
})(withStyles(styles)(Record))
