import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
  
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function getModalStyleScroll() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        maxHeight: "90%",
        overflow: 'scroll',
    };
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        minWidth: '320px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        borderRadius: 8,
    },
});
let self;
class CustomModal extends React.Component {
    state = {
        open: true,
        displayScroll: false,
    };

    static setDisplayScroll() {
        self.setState({
            displayScroll: true,
        })
    }

    static removeDisplayScroll() {
        self.setState({
            displayScroll: false,
        })
    }

    handleClose(){
        this.setState({
            displayScroll: false,
        })
        this.props.handleClose();
    };
    render() {
        const { classes } = this.props;
        self = this;
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.open}
                onClose={()=>this.handleClose()}
                keepMounted={true}
                children = {
                    <div style={this.state.displayScroll? getModalStyleScroll() : getModalStyle()} className={`${classes.paper} ${this.state.displayScroll? "customScrollbar" : ""}`}>
                    {
                        this.props.childComponent
                    }
                </div>
                }
                >
               
            </Modal>
        );
    }
}

export default withStyles(styles)(CustomModal)
