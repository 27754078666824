import React from 'react';
import HeaderBar from './HeaderBar';
import LeftDrawer from './LeftDrawer';

class HeaderAndMenu extends React.Component {

    constructor(props) {
        super(props);
        // initial state
        this.state = { open: false };
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu(){
        //set state
        this.setState({open: !this.state.open});
    }

    render() {
        return (
            <div>
                <HeaderBar title={this.props.title} history = {this.props.history}
                    toggleMenu={this.toggleMenu} className="App-Header"  />
                <LeftDrawer open={this.state.open}
                    toggleMenu={this.toggleMenu} />
            </div>
        );
    }
}

export default HeaderAndMenu;
