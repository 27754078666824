import React from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import { LoadingIndicator } from 'components';

import { getUserData } from 'redux/auth/actions';
import Routers from './routers';

import { LicenseInfo } from '@mui/x-license-pro';

const App = ({ token, userData, getUserData, selectedArea }) => {

  LicenseInfo.setLicenseKey('3b99461ba280278f8d358aa86d80e4a5Tz05NzYwOSxFPTE3NTcxODU0MjgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==');

  if (token && (!userData || !selectedArea)) {
    getUserData(token, (response) => {
      //TODO: The call back is been added is because this API was used in other pages so to handle 500 error status this function need to have call back function
    })
    return <LoadingIndicator />;
  }
  return <Routers token={token}/>;
};

export default withRouter(
  connect(
    state => ({
      token: state.authReducer.userToken,
      userData: state.authReducer.userData,
      selectedArea: state.authReducer.selectedArea
    }),
    { getUserData }
  )(App)
);