import { createMuiTheme } from "@material-ui/core/styles";

export const API_BASE_URL = 'https://diningmanager.ht-ss.com/';  //'https://diningmanagerdev.azurewebsites.net/'; //'https://diningmanager.ht-ss.com/'; // 'http://localhost:1426/'; // 'https://demo.ht-ss.com/'; // 'https://diningmanagerdev.azurewebsites.net/';

export const ERR_STATUS_500_MSG = "TableSide setup has not been done for this facility.  Please go to the Dining Manager website to fix this issue.";

export const Colors = {
  red: "#ee432f",
  green: "#46b9bc",
  yellow: "#f0c63e",
};

export const customTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#7eecee",
      main: "#46b9bc",
      dark: "#00898c",
      contrastText: "#fff",
    },
  },
});
