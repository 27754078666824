import React from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import * as _ from 'lodash';

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class SelectDiningArea extends React.Component {

	handleClose = () => {
		this.props.itemSelected();
    };
    
    renderDiningAreas() {
        let { diningAreas } = this.props;
        let uiArr = [];
        if(diningAreas && diningAreas.length !== 0) {
			if(this.props.enableAll){
				uiArr.push(
					<ListItem key={"listAll"} button onClick={() => this.props.itemSelected('all', 'All')}>
						<ListItemText primary={'All'} />
					</ListItem>
				);
				uiArr.push(
					<Divider key={"dividerAll"}/>
				);
			}
            _.forEach(diningAreas, diningArea => {
                uiArr.push(
                    <ListItem key={"list"+diningArea.Id} button onClick={() => this.props.itemSelected(diningArea.Id, diningArea.Name)}>
						<ListItemText primary={diningArea.Name} />
					</ListItem>
                );
                if(diningAreas.indexOf(diningArea) < diningAreas.length-1){
                    uiArr.push(
                        <Divider key={"divider"+diningArea.Id}/>
                    );
                }
            })
        }
        return uiArr;
    }

	render() {
		return (
			<div>
				<Dialog
					fullScreen
					open={this.props.open}
					onClose={this.handleClose}
					TransitionComponent={Transition}
				>
					<AppBar position="static">
						<Toolbar>
							<IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
								<CloseIcon />
							</IconButton>
							<Typography variant="title" color="inherit" >
							{this.props.title}
                            </Typography>
						</Toolbar>
					</AppBar>
					<List>
                        {this.renderDiningAreas()}
					</List>
				</Dialog>
			</div>
		);
	}
}

export default connect(state => ({
    diningAreas: state.authReducer.userData.diningAreas
}), null )(SelectDiningArea)
