import React from "react";
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from "@material-ui/core/Typography";

import { SelectDiningArea, SelectResident, LoadingIndicator } from 'components';

import * as orderActions from 'redux/order/actions';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    btn: {
        flex: 1,
        margin: '5px',
        loading: false
    },
    confirmMsg: {
        marginBottom: '30px',
        textAlign: 'center'
    }
})

const printStyles = {
    generalDiv: { 
        paddingBottom: '5px', 
        paddingTop: '5px',
        fontSize: '15px'
    },
    itemsText: {
        paddingBottom: '5px', 
        paddingTop: '5px',
        fontSize: '15px',
        // fontWeight: 'bold'
    },
    txtDiv: {
        paddingBottom: '15px', 
        paddingTop: '20px',
        fontSize: '15px'
    }
}

class Guest extends React.Component {

    state = {
        diningAreaSelectOpen: false,
        residentSelectOpen: false,
        selectedResident: " - Click to select -",
        selectedDiningArea: " - Click to select -",
        missingItem: false,
        managerRequired: false,
        additionalOrder: false,
        ticketText: '',
        selectedDiningAreaId: '',
        selectedResidentId: ''
    }

    openSelectDiningArea = () => {
        this.setState({ diningAreaSelectOpen: true });
    };
    openSelectResident = () => {
        this.setState({ residentSelectOpen: true });
    };
    
    diningAreaSelected = (diningAreaId, diningAreaName) => {
        console.log(diningAreaId, diningAreaName);
        this.setState({ diningAreaSelectOpen: false });
        if (diningAreaName) this.setState({ selectedDiningArea: diningAreaName, selectedDiningAreaId: diningAreaId });
    };
    
    residentSelected = (residentId, residentName) => {
        console.log(residentId, residentName);
        this.setState({ residentSelectOpen: false });
        if (residentName) this.setState({ selectedResident: residentName, selectedResidentId: residentId });
    };

    handleSubmit = () => {
        const { classes } = this.props;

        if(this.state.selectedResidentId)
        {
            this.props.setModalComponent(
                <Grid container justify="center" alignItems="center" direction="column">
                    <Grid>
                        <Typography variant="headline" component="h2" class={classes.confirmMsg}>
                            Are you sure you want to submit this Cook's Ticket?
                        </Typography>
                    </Grid>
                    <Grid container justify="center">
                        <Button
                            variant="raised"
                            color="primary"
                            onClick={()=>this.submitTicket()}
                            className={classes.btn}
                        >
                            Submit
                        </Button>
                        <Button
                            variant="raised"
                            color="primary"
                            onClick={()=>this.props.handleCloseModal()}
                            className={classes.btn}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>)
        }
        else {
            this.residentNotSelectedAlert();
        }
    }

    residentNotSelectedAlert() {
        const { classes } = this.props;
        this.props.setModalComponent(
            <Grid container justify="center" alignItems="center" direction="column">
                <div>Resident Must Be Selected</div><br />
                <div>
                    <Button
                        variant="raised"
                        color="primary"
                        onClick={() => this.props.handleCloseModal()}
                        className={classes.btn}>
                        Ok
                    </Button>
                </div>
            </Grid>)
    }

    submitTicket() {
        this.props.handleCloseModal();
        this.setState({
            loading: true
        });
        let data = {
            ticketText: this.state.ticketText,
            missingItem:  this.state.missingItem,
            managerRequired:  this.state.managerRequired,
            additionalOrder:  this.state.additionalOrder,
            diningAreaId:  this.state.selectedDiningAreaId,
            residentId:  this.state.selectedResidentId
        };

        this.props.submitTicket(data, response=>{
            const { success, error } = response;
            this.setState({loading: false});
            if(success){
                if(this.props.permissions.indexOf('Direct Print') !== -1){
                    var content = document.getElementById("printComponent");
                    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                    pri.document.open();
                    pri.document.write(content.innerHTML);
                    pri.document.close();
                    pri.focus();
                    pri.print();
                }
                this.clearSelection();
                // this.props.history.push('/takeorder/residents')                
            } else {
                this.props.showError(error)
            }
        });
    }

    clearSelection() {
        this.setState({
            missingItem: false,
            managerRequired: false,
            additionalOrder: false,
            selectedResident: " - Click to select -",
            selectedDiningArea: " - Click to select -",
            ticketText: ''
        })
    }

    getResidentById(id) {
        let residents = this.props.residents;
        let result = '';
        residents.forEach(resident => {
            if(resident.Id===id){
                result = resident.Name;
            }
        });
        return result;
    }

    getDiningAreaById(id) {
        let areas = this.props.diningAreas;
        let result = '';
        areas.forEach(area => {
            if(area.Id===id){
                result = area.Name;
            }
        });
        return result;
    }

    formatDate() {
        var newDate = new Date();
    
        var sMonth = this.padValue(newDate.getMonth() + 1);
        var sDay = this.padValue(newDate.getDate());
        var sYear = newDate.getFullYear();
        var sHour = newDate.getHours();
        var sMinute = this.padValue(newDate.getMinutes());
        var sAMPM = "AM";
    
        var iHourCheck = parseInt(sHour.toString(), 10);
    
        if (iHourCheck > 12) {
            sAMPM = "PM";
            sHour = iHourCheck - 12;
        }
        else if (iHourCheck === 0) {
            sHour = "12";
        }
    
        sHour = this.padValue(sHour);
    
        return sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
    }

    padValue(value) {
        return (value < 10) ? "0" + value : value;
    }

    render() { 
        let checkListText = '';
        if(this.state.missingItem){
            checkListText = checkListText + 'Missing Item, '
        }
        if(this.state.managerRequired){
            checkListText = checkListText + 'Manager Required, '
        }
        if(this.state.additionalOrder){
            checkListText = checkListText + 'Additional Order, '
        }
        if(checkListText !== ''){
            checkListText = checkListText.slice(0, checkListText.length-2)
        }
        return (
            <div>
                <iframe id="ifmcontentstoprint" title="cooksticketprint" style={{height: "0px", width: "0px", position: "absolute", display: "none"}}></iframe>
                {this.state.loading ? <LoadingIndicator /> :
                <div>
                <Card>
                    <CardContent>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={this.state.missingItem} 
                                        onChange={()=>{
                                            this.setState({
                                                missingItem: !this.state.missingItem
                                            })
                                        }}
                                        color="primary" 
                                    />
                                }
                                label="Missing Item"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={this.state.managerRequired} 
                                        onChange={()=>{
                                            this.setState({
                                                managerRequired: !this.state.managerRequired
                                            })
                                        }}
                                        color="primary" 
                                    />
                                }
                                label="Manager Required"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={this.state.additionalOrder} 
                                        onChange={()=>{
                                            this.setState({
                                                additionalOrder: !this.state.additionalOrder
                                            })
                                        }}
                                        color="primary" 
                                    />
                                }
                                label="Additional Order"
                            />
                        </FormGroup>
                    </CardContent>
                </Card>
                <Card>
                    <Button fullWidth variant="raised" onClick={this.openSelectResident}>
                        Resident: {this.state.selectedResident}
                    </Button>
                    <Button
                        fullWidth
                        variant="raised"
                        onClick={this.openSelectDiningArea}
                    >
                        Dining Area: {this.state.selectedDiningArea}
                    </Button>
                    <SelectDiningArea
                        open={this.state.diningAreaSelectOpen}
                        itemSelected={this.diningAreaSelected}
                        title="Select Dining Area"
                    />
                    <SelectResident
                        open={this.state.residentSelectOpen}
                        itemSelected={this.residentSelected}
                        title="Select Resident"
                    />
                </Card>
                <Card>
                    <TextField
                        id="multiline-flexible"
                        label="Cook's Ticket Text"
                        multiline
                        rows="4"
                        value={this.state.ticketText}
                        fullWidth
                        required
                        margin="normal"
                        onChange={(event)=>{
                            this.setState({
                                ticketText: event.target.value
                            })
                        }}
                    />
                </Card>
                <Button 
                    variant="raised" 
                    fullWidth 
                    type="submit" 
                    color="primary" 
                    onClick={this.handleSubmit}>
                    Submit Cook's Ticket
                </Button>
                </div>}

                <div id="printComponent" style={{position: 'absolute', top: '-9999px'}}>
                    <div style={{padding: '10px 5px 5px 5px', fontSize: '15px'}}>
                        {(this.state.missingItem || this.state.managerRequired || this.state.additionalOrder) &&
                            <div style={printStyles.generalDiv}>
                                <div style={{ fontSize: '23px', fontWeight: 'bold', paddingBottom: '5px', paddingTop: '5px' }}>
                                    {"Cook's Ticket"}
                                </div>
                                {/* <span>{checkListText}</span> */}
                                {this.state.missingItem && 
                                    <div style={printStyles.itemsText}>{'Missing Item '}</div>
                                }
                                {this.state.managerRequired && 
                                    <div style={printStyles.itemsText}>{'Manager Required '}</div>
                                }
                                {this.state.additionalOrder && 
                                    <div style={printStyles.itemsText}>{'Additional Order '}</div>
                                }
                            </div>
                        }
                        {this.state.selectedResidentId !== '' &&
                            <div style={printStyles.generalDiv}>
                                {this.getResidentById(this.state.selectedResidentId)}
                            </div>
                        }
                        {this.state.selectedDiningAreaId !== '' &&
                            <div style={printStyles.generalDiv}>
                                {this.getDiningAreaById(this.state.selectedDiningAreaId)}
                            </div>
                        }
                        {this.state.ticketText !== '' &&
                            <div style={printStyles.txtDiv}>
                               {this.state.ticketText}
                            </div>
                        }
                        <div style={{borderTop: '2px solid', paddingBottom: '5px', paddingTop: '15px', fontSize: '10px'}}>
                        {`Order Taken: ${this.formatDate()}`}
                    </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default connect(state => ({
    // residents: state.orderReducer.residents,
    diningAreas: state.authReducer.userData.diningAreas,
    residents: state.authReducer.userData.residents,
    permissions: state.authReducer.userData.permissions
}), {
    ...orderActions, 
})(withStyles(styles)(Guest))
