import React from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import * as _ from 'lodash';

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class SelectResident extends React.Component {

	handleClose = () => {
		this.props.itemSelected();
    };
    
    renderResidents() {
        let { residents } = this.props;
        let uiArr = [];
        if(residents && residents.length !== 0) {
            _.forEach(residents, resident => {
                uiArr.push(
                    <ListItem key={"list"+resident.Id} button onClick={() => this.props.itemSelected(resident.Id, resident.Name)}>
						<ListItemText primary={resident.Name} />
					</ListItem>
                );
                if(residents.indexOf(resident) < residents.length-1){
                    uiArr.push(
                        <Divider key={"divider"+resident.Id}/>
                    );
                }
            })
        }
        return uiArr;
    }

	render() {
		return (
			<div>

				<Dialog
					fullScreen
					open={this.props.open}
					onClose={this.handleClose}
					TransitionComponent={Transition}
				>
					<AppBar position="static">
						<Toolbar>
							<IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
								<CloseIcon />
							</IconButton>
							<Typography variant="title" color="inherit" >
								{this.props.title}
                            </Typography>
						</Toolbar>
					</AppBar>
					<List>
						{this.renderResidents()}
					</List>
				</Dialog>
			</div>
		);
	}
}

export default connect(state => ({
    residents: state.authReducer.userData.residents
}), null )(SelectResident)
