import * as actionTypes from './actionTypes';

const initialState = {
    // residents: localStorage.getItem("residents")!=='undefined' ? JSON.parse(localStorage.getItem("residents")) : [],
    guest: localStorage.getItem("guest")!=='undefined' ? JSON.parse(localStorage.getItem("guest")) : null,
    mealName: localStorage.getItem("mealName")!=='undefined' ? JSON.parse(localStorage.getItem("mealName")) : null,
    holdedOrderArray: [],
    submittedOrderArray: localStorage.getItem("submittedOrderArray")!==null ? JSON.parse(localStorage.getItem("submittedOrderArray")) : [],
    editableOrder: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_GUEST:
            localStorage.setItem("guest", JSON.stringify(action.payload));
            return Object.assign({}, state, {
                guest: action.payload
            })

        case actionTypes.SAVE_MEAL_NAME:
            localStorage.setItem("mealName", JSON.stringify(action.payload));
            return Object.assign({}, state, {
                mealName: action.payload
            })

        case actionTypes.HOLD_ORDER:

            let holdedOrderArray = state.holdedOrderArray;
            console.log(action.payload);

            action.payload.forEach(payloadorder => {
                holdedOrderArray.forEach(order => {
                    if (order.Order.ResidentId === payloadorder.Order.ResidentId && order.Order.ResidentId !== 0) {
                        holdedOrderArray.splice(holdedOrderArray.indexOf(order), 1);
                    }
                })
            })

            holdedOrderArray = holdedOrderArray.concat(action.payload);
            localStorage.setItem("holdedOrderArray", JSON.stringify(holdedOrderArray));
            
            return Object.assign({}, state, {
                holdedOrderArray: holdedOrderArray
            })

        case actionTypes.SAVE_EDITABLE_ORDER:
            return Object.assign({}, state, {
                editableOrder: action.payload
            })

        case actionTypes.CLEAR_PENDING:
            localStorage.setItem("holdedOrderArray", JSON.stringify([]));
            return Object.assign({}, state, {
                holdedOrderArray: []
            })

        case actionTypes.DELETE_PENDING:
            let holdArray = state.holdedOrderArray;
            holdArray.forEach(order => {
                if(action.payload.Order.ResidentId != null && action.payload.Order.ResidentId !== 0 
                    && action.payload.Order.Guest === null && order.Order.ResidentId === action.payload.Order.ResidentId){
                    holdArray.splice(holdArray.indexOf(order), 1);
                }

                else if(order.Order.ResidentId === 0 && order.Order.Guest != null && order.Order.Guest === action.payload.Order.Guest) {
                    holdArray.splice(holdArray.indexOf(order), 1);
                }
            })
            localStorage.setItem("holdedOrderArray", JSON.stringify(holdArray));
            return Object.assign({}, state, {
                holdedOrderArray: [...holdArray]
            })

        case actionTypes.SAVE_SUBMITTED_ORDER:
            let submittedOrderArray = state.submittedOrderArray;
            submittedOrderArray.forEach(order => {
                if(order.Order.ResidentId === action.payload.Order.ResidentId && order.Order.ResidentId !== 0){
                    submittedOrderArray.splice(submittedOrderArray.indexOf(order), 1);
                }
            })
            submittedOrderArray.unshift(action.payload);
            if(submittedOrderArray.length > 20){
                submittedOrderArray = submittedOrderArray.slice(0, 20);
            }
            localStorage.setItem("submittedOrderArray", JSON.stringify(submittedOrderArray));
            return Object.assign({}, state, {
                submittedOrderArray: submittedOrderArray
            })
        
        case actionTypes.UPDATE_RESIDENTS:
            return Object.assign({}, state, {
                residents: action.payload
            })

        case actionTypes.UPDATE_ACTIVE_MEALS:
            return Object.assign({}, state, {
                activemeals: action.payload
            })
        
        default:
            return state;
    }
}
