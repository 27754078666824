import React from "react";
import { connect } from 'react-redux';

import * as orderActions from 'redux/order/actions';

import { LoadingIndicator } from 'components';
import Typography from '@material-ui/core/Typography';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

class ResidentInfo extends React.Component {
    state = {
        loading: true,
        resident: null,
    }

    componentDidMount(){

        this.setState({
            loading: false,
            resident: this.props.residentId
        });

    }

    render() {

        const resident = this.props.residentId;
        return (
        <div>
            
            <div>
                { this.state.loading && <LoadingIndicator backgroundColor="white"/> }
                <Typography variant="headline" component="h2">
                    { resident && resident.Name }
                </Typography>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Diet</TableCell>
                            <TableCell>{ resident && resident.Diet }</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Diet Texture</TableCell>
                            <TableCell>{ resident && resident.DietTexture }</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Dislikes</TableCell>
                            <TableCell>{ resident && resident.Dislikes }</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Allergies</TableCell>
                            <TableCell>{ resident && resident.Allergies }</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Fluid Restriction</TableCell>
                            <TableCell>{ resident && resident.FluidRestrictions }</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Dining Area</TableCell>
                            <TableCell>{ resident && resident.DiningArea }</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Room</TableCell>
                            <TableCell>{ resident && resident.RoomNumber }</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Beverages</TableCell>
                            <TableCell>
                                {resident && resident.Beverages &&
                                    resident.Beverages.map(item => {
                                        return (
                                            <div>{item}</div>
                                        );
                                    })
                                }  
                            </TableCell>
                        </TableRow>                        
                        <TableRow>
                            <TableCell>Notes</TableCell>
                            <TableCell>
                                {resident && resident.Notes &&
                                    resident.Notes.map(item => {
                                        return (
                                            <div>{item}</div>
                                        );
                                    })
                                }  
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
            
        </div>
        );
    }
}

export default connect(null, {
    ...orderActions, 
})(ResidentInfo)