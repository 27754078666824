import React from 'react';
import { connect } from 'react-redux';

import { HeaderAndMenu, AlertMsg, CustomModal, LoadingIndicator } from 'components';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from '@material-ui/core/TableHead';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import { DatePicker } from '@atlaskit/datetime-picker';

import * as _ from 'lodash';

import { customTheme } from 'const';

import * as mealActions from 'redux/meal/actions';

const styles = theme => ({
    mealCard: {
        margin: '10px'
    },
    pendingCard: {
        margin: '10px',
        overflowX: 'auto'
    },
    mealSelect: {
        width: '100%'
    },
    tablecell: {
        padding: '4px 10px 4px 10px',
        minWidth: '100px'
    },
    btn: {
        flex: 1,
        margin: '5px'
    },
    modalItemContainer: {
        marginTop: '20px',
        width: '100%'
    },
    timeContainer: {
        width: '70%'
    }
})

class Meal extends React.Component {

    constructor(props) {
        super(props);
        // initial state
        this.state = {
            alertOpen: false,
            modalOpen: false,
            errorMsg: '',
            childComponent: null,
            loading: true,
            meals: null,
            seasons: null,
            pendingMeals: null,
            menus: null,
            days: null,
            selectedMeal: '',
            selectedSeason: '',
            selectedMenu: '',
            selectedDay: 1,
            now: true,
            selectedDate: this.getCurrentDateString(),
            selectedHour: '12',
            selectedMinute: '00',
            selectedAMPM: 'am'
        }
    }

    handleCloseAlert = () => {
        this.setState({ alertOpen: false });
    };

    handleCloseModal = () => {
        this.setState({ modalOpen: false });
    };

    showError = (errorMsg) => {
        this.setState({ 
            modalOpen: false,
            alertOpen: true,
            errorMsg: errorMsg,
        });
    }

    setModalComponent = childComponent => {
        this.setState({
            childComponent: childComponent,
            modalOpen: true
        });
    }

    componentDidMount(){
        this.getMeals();
    }

    getMeals() {
        this.props.getMeals(res => {
            const { result, error } = res;
            this.setState({loading: false});
            if(result){
                let seaons = result.availableMeals.Seasons;
                let meals = result.availableMeals.Meals;
                this.setState({
                    meals: meals,
                    seasons: seaons,
                    pendingMeals: result.pendingMeals.ActiveMeals,
                    menus: seaons.length > 0 ? seaons[0].Menus : null,
                    days: (seaons.length > 0 && seaons[0].Menus.length > 0) ? seaons[0].Menus[0].DaysInMenu : null,
                    selectedMeal: meals.length > 0 ? meals[0].Id : '',
                    selectedSeason: seaons.length > 0 ? seaons[0].Id : '',
                    selectedMenu: (seaons.length > 0 && seaons[0].Menus.length > 0) ? seaons[0].Menus[0].Id : '',
                    selectedDay: 1
                })  
            } else {
                this.showError(error)
            }
        });
    }

    renderMealList() {
        let list = [];
        // list.push(
        //     <MenuItem key={"no select"} value={"no select"}>None selected</MenuItem>
        // )
        if(this.state.meals!==null){
            _.forEach(this.state.meals, item => {
                list.push(
                    <MenuItem key={item.Id} value={item.Id}>{item.Name}</MenuItem>
                )
            })
        }
        return list;
    }

    selectMeal = event => {
        this.setState({
            selectedMeal: event.target.value
        })
    }

    renderSeasonList() {
        let list = [];
        // list.push(
        //     <MenuItem key={"no select"} value={"no select"}>None selected</MenuItem>
        // )
        if(this.state.seasons!==null){
            _.forEach(this.state.seasons, item => {
                list.push(
                    <MenuItem key={item.Id} value={item.Id}>{item.Name}</MenuItem>
                )
            })
        }
        return list;
    }

    selectSeason = event => {
        let seasonId = event.target.value;
        _.forEach(this.state.seasons, item => {
            if(item.Id === seasonId){
                this.setState({
                    menus: item.Menus,
                    selectedMenu: item.Menus.length > 0 ? item.Menus[0].Id : '',
                    days: item.Menus.length > 0 ? item.Menus[0].DaysInMenu : '',
                    selectedDay: 1
                })
            }
        })
        this.setState({
            selectedSeason: event.target.value
        })
    }

    renderMenuList() {
        let list = [];
        // list.push(
        //     <MenuItem key={"no select"} value={"no select"}>None selected</MenuItem>
        // )
        if(this.state.menus!==null){
            _.forEach(this.state.menus, item => {
                list.push(
                    <MenuItem key={item.Id} value={item.Id}>{item.Name}</MenuItem>
                )
            })
        }
        return list;
    }

    selectMenu = event => {
        let menuId = event.target.value;
        this.setState((state) =>{
            let menu = state.menus.find(m => m.Id === menuId);
            return {
                selectedMenu: menuId, 
                days: menu.DaysInMenu
            }
        });
    }

    renderDayList() {
        let list = [];
        // list.push(
        //     <MenuItem key={"no select"} value={"no select"}>None selected</MenuItem>
        // )
        if(this.state.days!==null){
            for(let i=1; i<=this.state.days; i++){
                list.push(
                    <MenuItem key={i} value={i}>{i}</MenuItem>
                )
            }
        }
        return list;
    }

    selectDay = event => {
        this.setState({
            selectedDay: event.target.value
        })
    }

    renderHours() {
        let list = [];
        for(let i=0; i<12; i++){
            if(i===0){
                list.push(
                    <MenuItem key={'12'} value={'12'}>{'12'}</MenuItem>
                )
            } else {
                list.push(
                    <MenuItem key={i} value={i.toString()}>{i.toString()}</MenuItem>
                )
            } 
        }
        return list;
    }

    selectHour = event => {
        this.setState({
            selectedHour: event.target.value
        })
    }

    renderMinutes() {
        let list = [];
        for(let i=0; i<4; i++){
            if(i===0){
                list.push(
                    <MenuItem key={'00'} value={'00'}>{'00'}</MenuItem>
                )
            } else {
                list.push(
                    <MenuItem key={i*15} value={(i*15).toString()}>{(i*15).toString()}</MenuItem>
                )
            } 
        }
        return list;
    }

    selectMinute = event => {
        this.setState({
            selectedMinute: event.target.value
        })
    }

    renderAMPM() {
        let list = [];
        list.push(
            <MenuItem key={'am'} value={'am'}>{'am'}</MenuItem>
        );
        list.push(
            <MenuItem key={'pm'} value={'pm'}>{'pm'}</MenuItem>
        );
        return list;
    }

    selectAMPM = event => {
        this.setState({
            selectedAMPM: event.target.value
        })
    }

    onDateTimeChange = value => {
        this.setState({
            selectedDate: value
        })
    }

    pad = (d) => {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }

    getCurrentDateString = () => {
        let date = new Date();
        return date.getFullYear()+'-'+(date.getMonth()+1).toString()+'-'+date.getDate()
    }

    createMeal = () => {
        let dateStr = '';
        const { selectedMeal, selectedSeason, selectedMenu, selectedDay, selectedDate, selectedHour, selectedMinute, selectedAMPM, now } = this.state;
        if(now) {
            let date = new Date();
            dateStr = date.getFullYear()+'-'+(date.getMonth()+1).toString()+'-'+date.getDate() + "T" + this.pad(date.getHours()) + ":" + this.pad(date.getMinutes()) + ":" + this.pad(date.getSeconds());
        } else {
            let hour = selectedHour
            if(hour === '12'){
                hour = 0;
            }
            if(Number(hour)<10){
                hour = `0${hour}`
            }
            if(selectedAMPM === 'pm') {
                hour = Number(hour) + 12
            }
            dateStr = `${selectedDate}T${hour}:${selectedMinute}:00`;
        }
        if(selectedMeal === '' || selectedSeason === '' || selectedMenu === '' || dateStr === '') {
            this.showError("You must select all required fields")
        } else {
            this.setState({loading: true});
            this.props.createMeal({ 
                MealId: selectedMeal, 
                SeasonId: selectedSeason, 
                MenuId: selectedMenu, 
                Day: selectedDay, 
                DiningDate: dateStr 
            }, res => {
                console.log("callback", res)
                
                if(res.error){
                    this.setState({loading: false});
                    this.showError(res.error)
                } else {
                    this.getMeals();
                }
            })
        }
    }

    deleteMeal = id => () => {
        this.handleCloseModal();
        this.setState({loading: true});
        this.props.deleteMeal(id, res => {
            if(res.error){
                this.setState({loading: false});
                this.showError(res.error)
            } else {
                this.getMeals();
            }
        })
    }

    getHumanDateTime = diningDate => {
        let arr = diningDate.split('T');
        let timeArr = arr[1].split(':');
        let dateArr = arr[0].split('-');
        var d = new Date(arr[0]+'T12:00'),
            months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
            days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
        return days[d.getDay()]+' '+months[dateArr[1]-1]+' '+dateArr[2]+' '+dateArr[0]+' '+timeArr[0]+':'+timeArr[1];
    }

    renderConfirmDelete = id => {
        const classes = this.props.classes;
        return  (
            <Grid container justify="center" alignItems="center" direction="column">
                Are you sure?
                
                <Grid container className={classes.modalItemContainer} justify="center">
               
                    <Button
                        variant="raised"
                        color="primary"
                        onClick={this.deleteMeal(id)}
                        className={classes.btn}
                    >
                        OK
                    </Button>
                    
                    <Button
                        variant="raised"
                        color="primary"
                        onClick={()=>this.handleCloseModal()}
                        className={classes.btn}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        );
    }

    render() {
        const classes = this.props.classes;
        return (
            <div className="order-container">
                <MuiThemeProvider theme={customTheme}>
                    <HeaderAndMenu title="DiningRD" />
                    {!this.state.loading ? 
                        <div>
                            <Card className={classes.mealCard}>
                                <CardContent>
                                    <Typography variant="headline" component="h2">
                                        Start a Meal
                                    </Typography>
                                    <List>
                                        <ListItem>
                                            Meal
                                        </ListItem>
                                        <ListItem>
                                            <Select
                                                value={this.state.selectedMeal}
                                                onChange={this.selectMeal}
                                                fullWidth
                                                disabled = {!this.state.meals || this.state.meals.length === 0}
                                            >
                                                { this.renderMealList() }
                                            </Select>
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem>
                                            Season
                                        </ListItem>
                                        <ListItem>
                                            <Select
                                                value={this.state.selectedSeason}
                                                onChange={this.selectSeason}
                                                fullWidth
                                                disabled = {!this.state.seasons || this.state.seasons.length === 0}
                                            >
                                                { this.renderSeasonList() }
                                            </Select>
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem>
                                            Menu
                                        </ListItem>
                                        <ListItem>
                                            <Select
                                                value={this.state.selectedMenu}
                                                onChange={this.selectMenu}
                                                fullWidth
                                                disabled = {!this.state.menus || this.state.menus.length === 0}
                                            >
                                                { this.renderMenuList() }
                                            </Select>
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem>
                                            Menu Day
                                        </ListItem>
                                        <ListItem>
                                            <Select
                                                value={this.state.selectedDay}
                                                onChange={this.selectDay}
                                                fullWidth
                                                disabled = {!this.state.days || this.state.days.length === 0}
                                            >
                                                { this.renderDayList() }
                                            </Select>
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem>
                                            Dining Date and Time
                                        </ListItem>
                                        <ListItem>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        checked={this.state.now} 
                                                        onChange={()=>{
                                                            this.setState({
                                                                now: !this.state.now,
                                                                selectedDate: this.getCurrentDateString()
                                                            }, ()=>{
                                                                if(!this.state.now){
                                                                    let customPicker = document.getElementById("custom-datepicker")
                                                                    let inputArr = customPicker.getElementsByTagName('input');
                                                                    for(let i=0; i<inputArr.length; i++){
                                                                        if(inputArr[i].id.indexOf('react-select') !== -1){
                                                                            inputArr[i].setAttribute("readOnly", "true");
                                                                        }
                                                                    }
                                                                }
                                                            })
                                                            
                                                        }}
                                                        color="primary" 
                                                    />
                                                }
                                                label="Now"
                                            />
                                        </ListItem>
                                        {!this.state.now && 
                                        <ListItem id="custom-datepicker">
                                            <DatePicker
                                                id="datetimepicker-3"
                                                value={this.state.selectedDate}
                                                onChange={this.onDateTimeChange}
                                                innerProps={{ style: { width: '50%' } }}
                                            />
                                            <Grid className={classes.timeContainer} justify="space-around" direction="row" container alignItems="center">
                                                <Select
                                                    value={this.state.selectedHour}
                                                    onChange={this.selectHour}                                                
                                                >
                                                    {this.renderHours()}
                                                </Select>
                                                <Typography>:</Typography>
                                                <Select
                                                    value={this.state.selectedMinute}
                                                    onChange={this.selectMinute}                                                
                                                >
                                                    {this.renderMinutes()}
                                                </Select>
                                                <Select
                                                    value={this.state.selectedAMPM}
                                                    onChange={this.selectAMPM}                                                
                                                >
                                                    {this.renderAMPM()}
                                                </Select>
                                            </Grid>
                                            
                                        </ListItem>
                                        }
                                    </List>
                                    <List>
                                        <ListItem>
                                            <Button
                                                fullWidth
                                                variant="raised"
                                                color="primary"
                                                onClick={this.createMeal}
                                            >
                                                Start a Meal
                                            </Button>
                                        </ListItem>
                                    </List>
                                    
                                </CardContent>
                            </Card>
                            { this.state.pendingMeals && 
                            <Card className={classes.pendingCard}>
                                <CardContent>
                                    <Typography variant="headline" component="h2">
                                        Queued Orders
                                    </Typography>
                                    <Table>
                                        <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.tablecell}>Dining Date</TableCell>
                                            <TableCell className={classes.tablecell}>Meal</TableCell>
                                            <TableCell className={classes.tablecell}>Menu</TableCell>
                                            <TableCell className={classes.tablecell}>Day</TableCell>
                                            <TableCell className={classes.tablecell}>Season</TableCell>
                                            <TableCell className={classes.tablecell}></TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {this.state.pendingMeals.map(row => {
                                            return (
                                            <TableRow key={row.Id}>
                                                <TableCell className={classes.tablecell}>
                                                {this.getHumanDateTime(row.DiningDate)}
                                                </TableCell>
                                                <TableCell className={classes.tablecell}>{row.Meal}</TableCell>
                                                <TableCell className={classes.tablecell}>{row.Menu}</TableCell>
                                                <TableCell className={classes.tablecell}>{row.Day}</TableCell>
                                                <TableCell className={classes.tablecell}>{row.Season}</TableCell>
                                                <TableCell className={classes.tablecell}>{row.IsActive ? 'Active' :
                                                    <IconButton>
                                                        <DeleteIcon onClick={() => 
                                                            this.setModalComponent(
                                                                <Grid container justify="center" alignItems="center" direction="column">
                                                                    {this.renderConfirmDelete(row.Id)}
                                                                </Grid>
                                                            )} />
                                                    </IconButton>}
                                                </TableCell>
                                            </TableRow>
                                            );
                                        })}
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                            }
                            
                        </div> : 
                        <LoadingIndicator />
                    }
                    <AlertMsg 
                        errorMsg={this.state.errorMsg} 
                        alertOpen={this.state.alertOpen} 
                        handleCloseAlert={this.handleCloseAlert}
                    />
                    <CustomModal 
                        open={this.state.modalOpen} 
                        handleClose={this.handleCloseModal}
                        // childComponent={<ResidentInfo residentId={this.state.residentId} showError={this.showError}/>}
                        childComponent={this.state.childComponent}
                    />
                </MuiThemeProvider>
            </div>
        );
    }
}

export default connect(state => ({
    availableMeals: state.mealReducer.availableMeals,
    pendingMeals: state.mealReducer.pendingMeals
}), {
    ...mealActions
})(withStyles(styles)(Meal))