import React from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// icons
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import * as orderActions from 'redux/order/actions';
import { LoadingIndicator } from 'components';
import Typography from "@material-ui/core/Typography";

import * as _ from 'lodash';

import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_FIELD, GridActionsCellItem } from '@mui/x-data-grid-pro';

const styles = theme => ({
    orderName: {
        fontSize: '2rem',
        lineHeight: '40px'
    },
    noItem: {
        marginTop: '100px'
    }
})

const printStyles = {
    generalInfo: {
        display: 'flex',
        paddingBottom: '0px',
        paddingTop: '0px'
    },
    divSpace: {
        paddingBottom: '0px',
        paddingTop: '0px'
    },
}

class PendingOrders extends React.Component {

    state = {
        loading: false,
        errorMsg: '',
        menuOrders: [],
        submitted: false,
        selectedSubmittedOrder: null,
        submittedOrderById: null,
        selectedOrdCount: 0,
        selectedOrdersList: [],
    }

    constructor(props) {

        super(props);
        this.gridref = React.createRef();
        
        this.DataColumn = [
            {
                field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
                headerName: '',
                minWidth: 1,
                maxWidth: 1,
                disableColumnMenu: true,
            },
            { field: 'name', headerName: 'Full Name' , flex:0.5, minWidth: 100},
            { field: 'diningarea', headerName: 'Dining Area', flex: 0.5, minWidth: 100,  headerAlign:"left", align: 'left' },
            { field: 'tablename', headerName: 'Table Name', flex: 0.5, minWidth: 100,  headerAlign:"left", align: 'left' },
            {
                field: 'actions',
                type: 'actions',
                minWidth: 150,
                align: 'left',
                renderCell: (params) => {
                    if (!params?.row?.isactive) {   
                        return [
                            <GridActionsCellItem 
                              icon={<DeleteIcon/>}
                              label='Delete'
                              onClick={() => {this.displayDeleteOrderConfirmationMsg(params.row.orderid)}}
                            />,
                            <GridActionsCellItem
                              style={{ marginLeft: 15}}
                              icon={<EditIcon/>}
                              label="Edit"
                              onClick={() => {this.gotoEdit(params.row.orderid)}}
                             />,
                          ]
                    }
                },
                getActions: () => {
                },
              }
          ]
    }

    submitPending() {

        this.setState({ loading: true });
        let heldorders = this.props.holdedOrderArray;
        var selorders = this.gridref.getSelectedRows();
        let filteredOrderArray = [];

        selorders.forEach((ord, keys) => {
            let hord = heldorders.find(x => x.Order.Id === ord.orderid);
            filteredOrderArray.push(hord);
        })

        this.props.submitPending(filteredOrderArray, response => {
            const { success, error } = response;
            this.setState({ loading: false });
            if (success) {
                let orders = [];
                filteredOrderArray.forEach(menuOrder => {
                    if (menuOrder.DirectPrint) {
                        orders.push(menuOrder);
                    }
                })
                if (orders.length > 0) {
                    this.setState({ menuOrders: orders }, () => {
                        var content = document.getElementById("printComponent");
                        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                        pri.document.open();
                        pri.document.write(content.innerHTML);
                        pri.document.close();
                        pri.focus();
                        pri.print();
                    })
                }
            } else {
                this.props.showError(error)
            }
        });

        var ordcount = heldorders.length - filteredOrderArray.length > 0 ? heldorders.length - filteredOrderArray.length : 0;
        this.setState({ selectedOrdCount: ordcount });
    }

    displayDeleteOrderConfirmationMsg = (orderid) => {

        const { classes } = this.props;

        this.props.setModalComponent(
        <Grid container justify="center" alignItems="center" direction="column">
            <Grid>
                <Typography variant="headline" component="h2" class={classes.confirmMsg}>
                    Are you sure you want to delete the order?
                </Typography>
            </Grid>
            <Grid container justify="center" style={{ marginTop: 15}}>
                <Button 
                    variant="raised"
                    color="primary"
                    onClick={() => this.deleteRow(orderid)}
                    className={classes.btn}
                >
                    Yes
                </Button>
                <Button
                    style={{ marginLeft: 15}}
                    variant="raised"
                    color="primary"
                    onClick={() => this.props.handleCloseModal()}
                    className={classes.btn}
                >
                    No
                </Button>
            </Grid>
        </Grid>);
    }

    deleteRow = async (orderid) => {

        this.props.handleCloseModal();
        const { holdedOrderArray } = this.props;
        const heldord = holdedOrderArray.find(x => x.Order.Id === orderid);  
        await this.props.deletePending(heldord);
    }

    gotoEdit = async (orderid) => {

        const { holdedOrderArray } = this.props;
        const order = holdedOrderArray.find(x => x.Order.Id === orderid);  

        let residentId = order.Order.ResidentId === null ? 0 : order.Order.ResidentId;
        this.setState({ loading: true });
        this.props.saveEditableOrder(order);
        if (residentId === 0 && order.Order.Guest) {
            this.props.saveGuestInfo({
                guestName: order.Order.Guest,
                paymentDone: order.Order.GuestBillingTypeId,
                selectedResident: order.Order.GuestOfResidentId,
                selectedGuestType: order.Order.GuestTypeId
            });
            this.props.history.push(`/takeorder/guest/order?from=sp`);
        } else {
            await this.props.getActiveMealsByResident(residentId, response => {
                const { activeMeals, error } = response;
                if (error) {
                    var errorMsg = '';
                    if (error === 'Failed') {
                        errorMsg = `Request Failed`;
                    }
                    else {
                        errorMsg = error;
                    }
                    this.setState({
                        loading: false,
                    });
                    this.props.showError(errorMsg);
                }
                if (activeMeals) {
                    this.props.history.push(`/takeorder/residents/order/${residentId}/${order.Order.TableSideSetupId}?current=true&from=sp`);
                }
            })
        }
    }

    formatDate() {
        var newDate = new Date();

        var sMonth = this.padValue(newDate.getMonth() + 1);
        var sDay = this.padValue(newDate.getDate());
        var sYear = newDate.getFullYear();
        var sHour = newDate.getHours();
        var sMinute = this.padValue(newDate.getMinutes());
        var sAMPM = "AM";

        var iHourCheck = parseInt(sHour.toString(), 10);

        if (iHourCheck > 12) {
            sAMPM = "PM";
            sHour = iHourCheck - 12;
        }
        else if (iHourCheck === 0) {
            sHour = "12";
        }

        sHour = this.padValue(sHour);

        return sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
    }

    padValue(value) {
        return (value < 10) ? "0" + value : value;
    }

    getResidentById(id) {
        let residents = this.props.residents;
        let result = '';
        residents.forEach(resident => {
            if (resident.Id === id) {
                result = resident.Name;
            }
        });
        return result;
    }

    getDiningAreaById(id) {
        let areas = this.props.diningAreas;
        let result = '';
        areas.forEach(area => {
            if (area.Id === id) {
                result = area.Name;
            }
        });
        return result;
    }

    renderPrintItems(menuInfo) {
        let menuList = [];
        _.forEach(menuInfo.modalItems.MenuItems, item => {
            let quantity;
            if (item.Quantity === 0.5) {
                quantity = '1/2';
            } else {
                quantity = item.Quantity;
            }
            menuList.push(
                <div style={printStyles.divSpace}>
                    <span>{`${quantity} - ${item.PortionSize} ${item.RecipeName}`}</span>
                </div>
            )
        });
        _.forEach(menuInfo.modalItems.AlternateItems, item => {
            let quantity;
            if (item.Quantity === 0.5) {
                quantity = '1/2';
            } else {
                quantity = item.Quantity;
            }
            menuList.push(
                <div style={printStyles.divSpace}>
                    <span>{`${quantity} - ${item.PortionSize} ${item.RecipeName}`}</span>
                </div>
            )
        });
        return menuList;
    }

    handleReprint = (orderId) => {
        this.setState({ loading: true });
        this.props.reprintSubmittedOrder(orderId, response => {
            const { data, error } = response;
            this.setState({ loading: false });
            if (data === "") {
                this.props.setModalComponent(
                    <Grid container justify="center" alignItems="center" direction="column">
                        <div>Reprinted</div><br />
                        <div>
                            This order was successfully queued for reprinting. 
                        </div><br />
                        <div>
                            <Button
                                variant="raised"
                                color="primary"
                                onClick={() => this.props.handleCloseModal()}
                            >
                                Ok
                            </Button>
                        </div>
                    </Grid>
                )
                            } else {
                this.props.showError(error)
            }
        });
    }

    handlePrintSummary = (orderId) => {
        this.setState({ loading: true });
        this.props.submitPrintSummary(orderId, response => {
            const { success, error } = response;
            this.setState({ loading: false });
            if (success) {
                this.props.setModalComponent(
                    <Grid container justify="center" alignItems="center" direction="column">
                        <div>
                            Summary receipt printed. 
                        </div><br />
                        <div>
                            <Button
                                variant="raised"
                                color="primary"
                                onClick={() => this.props.handleCloseModal()}
                            >
                                Ok
                            </Button>
                        </div>
                    </Grid>
                )
            } else {
                this.props.showError(error)
            }
        });
    }

    renderPendings() {
        const { classes, holdedOrderArray } = this.props;
        
        if (holdedOrderArray.length === 0) {
            return <Grid container className={classes.noItem} justify="center" alignItems="center">No Items Found</Grid>
        } else {

            const holdedOrderArraySort = holdedOrderArray.sort((a, b) => {
                return a.Order.DiningAreaId - b.Order.DiningAreaId || (a.Order.TableName??'').localeCompare(b.Order.TableName??'');
            });

            console.log(holdedOrderArraySort);

            let pendingList = [];
            let rowid = 0;
            _.forEach(holdedOrderArraySort, order => {
                let resname;
                if (order.ResidentName?.trim().length > 0) {
                    resname = order.ResidentName;
                } else {
                    resname = order.Order.Guest;
                }


                let dinarea = '';        
                if (order.Order.DiningAreaId != null && order.Order.DiningAreaId > 0)
                    dinarea = this.getDiningAreaById(order.Order.DiningAreaId);

                pendingList.push({id: rowid, residentid: order.Order.ResidentId, orderid: order.Order.Id, 
                                name: resname, diningarea: dinarea, tablename: order.Order.TableName})

                rowid++;                
            })

            return (<DataGridPro
                checkboxSelection
                rows={pendingList}
                apiRef={(c) => this.gridref = c }
                columns={this.DataColumn}
                getDetailPanelHeight={() => 'auto'}
                rowHeight={30}
                onRowSelectionModelChange={(selection) => {
                    this.onPendingOrderClick(selection);
                  }}
            />);
        }
    }

    onPendingOrderClick = () => {

        var selitem = this.gridref.getSelectedRows();
        this.setState({ selectedOrdCount: selitem.size });
    }

    renderPrintOrders() {
        const classes = this.props.classes;
        let menuInfos = this.state.menuOrders;
        let menuList = [];
        menuInfos.forEach(menuInfo => {
            menuList.push(
                <div style={{ padding: '10px 5px 5px 5px', fontSize: '15px' }}>
                    {menuInfo.Order.ResidentId === 0 ?
                        <div style={{ fontSize: '23px', fontWeight: 'bold', paddingBottom: '5px', paddingTop: '5px' }}>
                            {menuInfo.Order.Guest}
                        </div> :
                        <div style={{ fontSize: '23px', fontWeight: 'bold', paddingBottom: '5px', paddingTop: '5px' }}>
                            {this.getResidentById(menuInfo.Order.ResidentId)}
                        </div>
                    }

                    {this.getResidentById(menuInfo.Order.GuestOfResidentId) !== "" &&
                        <div style={printStyles.divSpace}>
                            Guest of: {this.getResidentById(menuInfo.Order.GuestOfResidentId)}
                        </div>
                    }
                    {menuInfo.Order.ResidentId === 0 &&
                        <div style={printStyles.divSpace}>
                            Payment Status: {menuInfo.Order.GuestBillingTypeId === "0" ? 'No Charge' : menuInfo.Order.GuestBillingTypeId === "1" ? 'Payment Received' : 'Bill to Resident' }
                        </div>}
                    <div style={{ borderBottom: '2px solid', paddingBottom: '5px', marginBottom: '5px' }}>
                        {this.getDiningAreaById(menuInfo.Order.DiningAreaId) !== "" &&
                            <div style={printStyles.generalInfo}>
                                <div style={printStyles.rightText}>
                                    Dining Room: &nbsp;
                                </div>
                                {this.getDiningAreaById(menuInfo.Order.DiningAreaId)}
                            </div>
                        }
                        {menuInfo && menuInfo.DietName !== "N/A" && menuInfo.DietName !== "" &&
                            <div style={printStyles.generalInfo}>
                                <div style={printStyles.rightText}>
                                    Diet: &nbsp;
                                </div>
                                {menuInfo.DietName}
                                <span className={classes.generalText}>{menuInfo.DietTextureName !== '' && menuInfo.DietTextureName !== 'Regular' && ` / ${menuInfo.DietTextureName}`}</span>
                            </div>
                        }
                        {menuInfo.Order.ResidentId !== 0 && menuInfo.RoomBed && menuInfo.RoomBed !== '' && menuInfo.RoomBed !== 'N/A' &&
                            <div style={printStyles.generalInfo}>
                                <div style={printStyles.rightText}>
                                    Room Number: &nbsp;
                                </div>
                                {menuInfo.RoomBed}
                            </div>
                        }
                        {menuInfo && menuInfo.Notes && menuInfo.Notes.length > 0 &&
                            <div style={printStyles.generalInfo}>
                                <div style={printStyles.rightText}>
                                    Notes: &nbsp;
                            </div>
                                {menuInfo.Notes.map(item => {
                                    if (menuInfo.Notes.indexOf(item) === menuInfo.Notes.length - 1) {
                                        return item;
                                    } else {
                                        return `${item}, `;
                                    }
                                })}
                            </div>}
                        {}
                        {menuInfo && menuInfo.DietOther !== "N/A" && menuInfo.DietOther !== "" &&
                            <div style={printStyles.generalInfo}>
                                <div style={printStyles.rightText}>
                                    Diet Other:&nbsp;
                                </div>
                                {menuInfo.DietOther}
                            </div>
                        }
                        {menuInfo && menuInfo.FluidRestriction !== "N/A" && menuInfo.FluidRestriction !== "" &&
                            <div style={printStyles.generalInfo}>
                                <div style={printStyles.rightText}>
                                    Restriction: &nbsp;
                                </div>
                                {menuInfo.FluidRestriction}
                            </div>
                        }
                        {menuInfo && menuInfo.AdaptiveEquipment !== "N/A" && menuInfo.AdaptiveEquipment !== "" &&
                            <div style={printStyles.generalInfo}>
                                <div style={printStyles.rightText}>
                                    Adaptive Equip: &nbsp;
                                </div>
                                {menuInfo.AdaptiveEquipment}
                            </div>
                        }
                    </div>

                    {menuInfo && menuInfo.Allergies !== "N/A" && menuInfo.Allergies !== "" &&
                        <div style={printStyles.divSpace}>
                            Allergies: &nbsp;{menuInfo.Allergies}
                        </div>
                    }
                    {this.renderPrintItems(menuInfo)}
                    {menuInfo && menuInfo.Order.Note !== "N/A" && menuInfo.Order.Note !== "" &&
                        <div style={printStyles.divSpace}>
                            Note: &nbsp;{menuInfo.Order.Note}
                        </div>
                    }
                    <div style={{ borderTop: '2px solid', marginTop: '5px', paddingBottom: '5px', paddingTop: '5px', fontSize: '10px' }}>
                        {`Order Taken: ${this.formatDate()}, by ${this.props.email}`}
                    </div>
                    {menuInfos.indexOf(menuInfo) !== menuInfos.length - 1 &&
                        <p style={{ pageBreakBefore: 'always' }}></p>
                    }
                </div>
            )

        })
        return menuList;
    }

    render() {

        return (
            <div>
                <iframe id="ifmcontentstoprint" title="pendingprint" style={{ height: "0px", width: "0px", position: "absolute", display: "none" }}></iframe>
                {this.state.loading ? <LoadingIndicator /> : (
                    <div className="paperContent customScrollbar">
                        { this.renderPendings() }
                        {this.state.selectedOrdCount > 0 && <Button style={{top:10}}
                            variant="raised"
                            color="primary"
                            onClick={() => { this.submitPending(); }}
                        >
                            Submit to Kitchen
                        </Button>}
                    </div>
                )}

                <div id="printComponent" style={{ position: 'absolute', top: '-9999px' }}>
                    {
                        this.renderPrintOrders()
                    }
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    holdedOrderArray: state.orderReducer.holdedOrderArray,
    submittedOrderArray: state.orderReducer.submittedOrderArray,
    diningAreas: state.authReducer.userData.diningAreas,
    residents: state.authReducer.userData.residents,
    email: state.authReducer.email
}), {
        ...orderActions,
    })(withStyles(styles)(PendingOrders))
