import React from 'react';
import { HeaderAndMenu, CustomAppBar, AlertMsg, CustomModal } from 'components';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { customTheme } from 'const';

import Residents from './Residents';
import ActiveMeals from './Residents/ActiveMeals';
import Order from './Order';
import Guest from './Guest';
import Cooksticket from './Cooksticket';
import PendingOrders from './PendingOrders';
import SubmittedOrders from './SubmittedOrders';

class TakeOrder extends React.Component {

    state = {
        alertOpen: false,
        modalOpen: false,
        errorMsg: '',
        childComponent: null,
    }

    handleCloseAlert = () => {
        this.setState({ alertOpen: false });
    };

    handleCloseModal = () => {
        this.setState({ modalOpen: false });
    };

    showError = (errorMsg) => {
        this.setState({ 
            modalOpen: false,
            alertOpen: true,
            errorMsg: errorMsg,
        });
    }

    setModalComponent = childComponent => {
        this.setState({
            childComponent: childComponent,
            modalOpen: true
        });
    }

    onChange = (value) => {
        if(value===0){
            this.props.history.push('/takeorder/residents');
        } else if(value===1) {
            this.props.history.push('/takeorder/guest');
        } else if(value===2) {
            this.props.history.push('/takeorder/cooksticket');
        } else if(value===3) {
            this.props.history.push('/takeorder/pending');
        }else if(value===4) {
            this.props.history.push('/takeorder/submitted');
        }
    }

    render() {
        let path = this.props.location.pathname;
        // console.log("path", path)
        let value = 0;
        if(path.indexOf('/takeorder/guest')===0){
            value = 1;
        }
        if(path.indexOf('/takeorder/cooksticket')===0){
            value = 2;
        }
        if(path.indexOf('/takeorder/pending')===0){
            value = 3;
        }
        if(path.indexOf('/takeorder/submitted')===0){
            value = 4;
        }
        return (
            <div className="order-container">
                <MuiThemeProvider theme={customTheme}>
                    <HeaderAndMenu title="DiningRD" history = {this.props.history}/>
                    <CustomAppBar onChange = {this.onChange} value={value}/>
                    {path==='/takeorder/residents' &&  
                        <Residents 
                            handleCloseAlert = {this.handleCloseAlert} 
                            handleCloseModal = {this.handleCloseModal}  
                            showError = {this.showError}
                            setModalComponent = {this.setModalComponent}
                            history = {this.props.history}
                        />
                    }
                    {path.indexOf('/takeorder/residents/meals')===0 &&  
                        <ActiveMeals 
                            residentId={this.props.match.params.residentId}
                            tableSideSetupId = { this.props.match.params.tableSideSetupId }
                            handleCloseAlert = {this.handleCloseAlert} 
                            handleCloseModal = {this.handleCloseModal}
                            showError = {this.showError}
                            setModalComponent = {this.setModalComponent}
                        />
                    }
                    {path.indexOf('/takeorder/residents/order')===0 &&  
                        <Order 
                            residentId={this.props.match.params.residentId}
                            tableSideSetupId = { this.props.match.params.tableSideSetupId }
                            handleCloseAlert = {this.handleCloseAlert} 
                            handleCloseModal = {this.handleCloseModal}
                            showError = {this.showError}
                            setModalComponent = {this.setModalComponent}
                            history = {this.props.history}
                        />
                    }
                    {path==='/takeorder/guest' &&  
                        <Guest
                            handleCloseAlert = {this.handleCloseAlert} 
                            handleCloseModal = {this.handleCloseModal}
                            showError = {this.showError}
                            setModalComponent = {this.setModalComponent}
                            history = {this.props.history}
                        />
                    }
                    {path.indexOf('/takeorder/guest/order')===0 &&  
                        <Order 
                            residentId={'guest'}
                            handleCloseAlert = {this.handleCloseAlert} 
                            handleCloseModal = {this.handleCloseModal}
                            showError = {this.showError}
                            setModalComponent = {this.setModalComponent}
                            history = {this.props.history}
                        />
                    }
                    {path==='/takeorder/cooksticket' &&  
                        <Cooksticket
                            handleCloseAlert = {this.handleCloseAlert} 
                            handleCloseModal = {this.handleCloseModal}
                            showError = {this.showError}
                            setModalComponent = {this.setModalComponent}
                            history = {this.props.history}
                        />
                    }
                    {path==='/takeorder/pending' &&  
                        <PendingOrders
                            handleCloseAlert = {this.handleCloseAlert} 
                            handleCloseModal = {this.handleCloseModal}
                            showError = {this.showError}
                            setModalComponent = {this.setModalComponent}
                            history = {this.props.history}
                        />
                    }
                    {path==='/takeorder/submitted' &&  
                        <SubmittedOrders
                            handleCloseAlert = {this.handleCloseAlert} 
                            handleCloseModal = {this.handleCloseModal}
                            showError = {this.showError}
                            setModalComponent = {this.setModalComponent}
                            history = {this.props.history}
                        />
                    }
                    <AlertMsg 
                        errorMsg={this.state.errorMsg} 
                        alertOpen={this.state.alertOpen} 
                        handleCloseAlert={this.handleCloseAlert}
                    />
                    <CustomModal 
                        open={this.state.modalOpen} 
                        handleClose={this.handleCloseModal}
                        // childComponent={<ResidentInfo residentId={this.state.residentId} showError={this.showError}/>}
                        childComponent={this.state.childComponent}
                    />
                </MuiThemeProvider>
            </div>
        );
    }
}

export default TakeOrder;