import React from "react";
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const Wrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${props => props.background};
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
`

const styles = theme => ({
    progress: {
      margin: theme.spacing.unit * 2,
    },
});

class LoadingIndicator extends React.Component {
    render() {
        const { classes, backgroundColor } = this.props;
        let background = backgroundColor ? backgroundColor : 'rgba(0,0,0,0.5)';
        return (
            <Wrapper background={background}>
                <CircularProgress className={classes.progress} />
            </Wrapper>
        );
    }
}

export default withStyles(styles)(LoadingIndicator)
