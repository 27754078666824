import * as actionTypes from './actionTypes';
import request from 'utils/request';
import errorHandler from 'utils/error';
import axios from 'axios';
import { API_BASE_URL } from 'const';

export function getResidentsByDiningArea(diningAreaId, callback) {
  return (dispatch) => {
    const token = localStorage.getItem("userToken")
    let url = '';
    if (diningAreaId === 'all') {
      url = `/api/resident/all/${token}`
    } else {
      url = `/api/tableside/residentsByDiningAreaV2/${token}/${diningAreaId}`;
    }
    request({
      url: url,
      method: 'Get'
    })
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_RESIDENTS,
          payload: res.data
        });
        callback({
          residents: res.data
        });
      })
      .catch(({ ...error }) => {
        callback({
          error: errorHandler(error)
        })
      })
  };
}

export function getRecipeImage(recipeid, callback) {
  return (dispatch) => {
    request({
      url: `/api/recipe/RecipeImage/${recipeid}`,
      method: 'Get'
    })
      .then((res) => {
        callback({
          resident: res.data
        });

      })
      .catch(({ ...error }) => {
        callback({
          error: errorHandler(error)
        })
      })
  };
}

export function getResidentInfo(residentId, callback) {

  return async (dispatch) => {

    const token = localStorage.getItem("userToken")
    
    let config = {
      baseURL: API_BASE_URL,
      method: 'get',
      maxBodyLength: Infinity,
      url: API_BASE_URL + `/api/Resident/ResidentInfo/${token}/${residentId}`,
      headers: {
        'Bearer': token
      }
    };

    await axios.request(config)
      .then((res) => {
        callback({
          data: res.data
        });
      })
      .catch((error) => {
        console.log(error);
      });
    }
}

export function getActiveMealsByResident(residentId, callback) {
  return (dispatch) => {
    request({
      url: `/api/tableside/ActiveMeals/${residentId}`,
      method: 'Post'
    })
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_ACTIVE_MEALS,
          payload: res.data
        });
        callback({
          activeMeals: res.data
        });

      })
      .catch(({ ...error }) => {
        callback({
          error: errorHandler(error)
        })
      })
  };
}

export function getMenu(residentId, tableSideSetupId, callback) {
  return (dispatch) => {
    let data = residentId === 'guest' ? {
      isGuest: true
    } : {
        residentId: residentId,
        isGuest: residentId === 'guest',
        tableSideSetupId: tableSideSetupId
      }
    request({
      url: '/api/tableside/GetMenu/',
      method: 'Post',
      data: data
    })
      .then((res) => {
        callback({
          resident: res.data
        });

      })
      .catch(({ ...error }) => {
        callback({
          error: errorHandler(error)
        })
      })
  };
}

export function getMenuV2(residentId, tableSideSetupId, callback) {
  return (dispatch) => {
    let data = residentId === 'guest' ? {
      isGuest: true
    } : {
        residentId: residentId,
        isGuest: residentId === 'guest',
        tableSideSetupId: tableSideSetupId
      }
    request({
      url: '/api/tableside/GetMenuV2/',
      method: 'Post',
      data: data
    })
      .then((res) => {
        callback({
          resident: res.data
        });

      })
      .catch(({ ...error }) => {
        callback({
          error: errorHandler(error)
        })
      })
  };
}

export function submitOrder(orderInfo, ableToStore, callback) {
  return (dispatch) => {
    request({
      url: '/api/tableside/PostResidentOrderV2',
      method: 'Post',
      data: orderInfo
    })
      .then((res) => {
        console.log("res", res)
        if (ableToStore) {
          dispatch({
            type: actionTypes.SAVE_SUBMITTED_ORDER,
            payload: orderInfo
          });
        }
        callback({
          success: "success"
        });
      })
      .catch(({ ...error }) => {
        callback({
          error: errorHandler(error)
        })
      })
  };
}

export function submitOrderV2(orderInfo, ableToStore, callback) {
  return (dispatch) => {
    request({
      url: '/api/tableside/PostResidentOrderV3',
      method: 'Post',
      data: orderInfo
    })
      .then((res) => {
        console.log("res", res)
        if (ableToStore) {
          dispatch({
            type: actionTypes.SAVE_SUBMITTED_ORDER,
            payload: orderInfo
          });
        }
        callback({
          success: "success"
        });
      })
      .catch(({ ...error }) => {
        callback({
          error: errorHandler(error)
        })
      })
  };
}

export function submitPending(pendingArray, callback) {
  return (dispatch) => {
    let requestArray = [];
    pendingArray.forEach(order => {
      let req = [request({
        url: '/api/tableside/PostResidentOrderV3',
        method: 'Post',
        data: order
      }), 
      request({
        url: `/api/tableside/DeleteHeldOrder/${order.Order.Id}`,
        method: 'DELETE'
      })]
      requestArray.push(req);
    })
    Promise.all(requestArray)
      .then(response => {
        console.log("res", response)
        pendingArray.forEach(order => {
          dispatch({
            type: actionTypes.DELETE_PENDING,
            payload: order
          });
          dispatch({
            type: actionTypes.SAVE_SUBMITTED_ORDER,
            payload: order
          });
        })

        callback({
          success: "success"
        });
      })
      .catch(({ ...error }) => {
        console.log("error", error)

        callback({
          error: errorHandler(error)
        })
      })
  }
}

export function deletePending(orderInfo) {
  return (dispatch) => {
    request({
      url: `/api/tableside/DeleteHeldOrder/${orderInfo.Order.Id}`,
      method: 'DELETE'
    })
      .then((res) => {
        console.log("res", res)
          dispatch({
            type: actionTypes.DELETE_PENDING,
            payload: orderInfo
          });
      })
      .catch(({ ...error }) => {
        console.log(error)
      })
  };
}

export function postResidentHoldOrder(menuInfo, callback) {
  return (dispatch) => {
    request({
      url: '/api/tableside/PostResidentHoldOrderV2',
      method: 'Post',
      data: menuInfo
    })
      .then((res) => {
        console.log("res", res)

        let orderarr = [res.data];
        dispatch({
            type: actionTypes.HOLD_ORDER,
            payload: orderarr
        });
        callback({
          success: "success"
        });
      })
      .catch(({ ...error }) => {
        callback({
          error: errorHandler(error)
        })
      })
  };
}

export function getHeldOrders(callback) {
  return (dispatch) => {
    request({
      url: `/api/tableside/HeldOrdersV2`,
      method: 'Get'
    })
      .then((res) => {
        callback({
          heldOrders: res.data
        });

      })
      .catch(({ ...error }) => {
        callback({
          error: errorHandler(error)
        })
      })
  };
}

export function getHeldOrderByID(orderId, callback) {
  return (dispatch) => {
    request({
      url: `/api/tableside/HeldOrder/${orderId}`,
      method: 'Get'
    })
      .then((res) => {
        callback({
          heldOrders: res.data
        });

      })
      .catch(({ ...error }) => {
        callback({
          error: errorHandler(error)
        })
      })
  };
}

export function deleteHeldOrder(Id, callback) {
  return (dispatch) => {
    request({
        url: `/api/tableside/DeleteHeldOrder/${Id}`,
        method: 'Post',
    })
      .then((res) => {
        console.log("res", res)
        callback({
            success: "success"
        });

      })
      .catch((error) => {
        console.log("error", error)

        callback({
            error: errorHandler(error)
        })
      })
  };
}

export function saveEditableOrder(orderInfo) {
  return ({
    type: actionTypes.SAVE_EDITABLE_ORDER,
    payload: orderInfo
  })
}

export function submitTicket(data, callback) {
  return (dispatch) => {
    request({
      url: '/api/tableside/CooksTicketV2',
      method: 'Post',
      data: data
    })
      .then((res) => {
        console.log("res", res)
        callback({
          success: "success"
        });

      })
      .catch(({ ...error }) => {
        callback({
          error: errorHandler(error)
        })
      })
  };
}

export function saveGuestInfo(guestInfo) {
  return ({
    type: actionTypes.SAVE_GUEST,
    payload: guestInfo
  })
}

export function saveMealName(mealName) {
  return ({
    type: actionTypes.SAVE_MEAL_NAME,
    payload: mealName
  })
}

export function getAllSubmittedOrders(callback) {
  return (dispatch) => {
    request({
      url: `/api/tableside/SubmittedOrders`,
      method: 'Get'
    })
      .then((res) => {
        callback({
          resident: res.data
        });

      })
      .catch(({ ...error }) => {
        callback({
          error: errorHandler(error)
        })
      })
  };
}

export function getAllSubmittedOrderById(Id, callback) {
  return (dispatch) => {
    request({
      url: `/api/tableside/SubmittedOrderV2/${Id}`,
      method: 'Get'
    })
      .then((res) => {
        callback({
          data: res.data
        });

      })
      .catch(({ ...error }) => {
        callback({
          error: errorHandler(error)
        })
      })
  };
}

export function getFutureOrders(orderId, callback) {

  return async (dispatch) => {

    const token = localStorage.getItem("userToken")
    
    let config = {
      baseURL: API_BASE_URL,
      method: 'get',
      maxBodyLength: Infinity,
      url: API_BASE_URL + `/api/tableside/GetFutureOrders/${orderId}`,
      headers: {
        'Bearer': token
      }
    };

    await axios.request(config)
      .then((res) => {
        callback({
          data: res.data
        });
      })
      .catch((error) => {
        console.log(error);
      });
    }
}

export function getAllSubmittedOrderByIdV2 (Id, callback) {
  return async (dispatch) => {

    const token = localStorage.getItem("userToken")
    
    let config = {
      baseURL: API_BASE_URL,
      method: 'get',
      maxBodyLength: Infinity,
      url: API_BASE_URL + `/api/tableside/SubmittedOrderV3/${Id}`,
      headers: {
        'Bearer': token
      }
    };

    await axios.request(config)
      .then((res) => {
        callback({
          data: res.data
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function DeleteFutureOrderAsync (Id, callback) {
  return async (dispatch) => {

    const token = localStorage.getItem("userToken")
    
    let config = {
      baseURL: API_BASE_URL,
      method: 'post',
      maxBodyLength: Infinity,
      url: API_BASE_URL + `/api/tableside/DeleteFutureOrder/${Id}`,
      headers: {
        'Bearer': token
      }
    };

    await axios.request(config)
      .then((res) => {
        callback({
          success: "success"
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getDiningRoomFromResidentId(residentId, callback) {

  return async (dispatch) => {

    const token = localStorage.getItem("userToken")
    
    let config = {
      baseURL: API_BASE_URL,
      method: 'get',
      maxBodyLength: Infinity,
      url: API_BASE_URL + `/api/tableside/GetDiningRoomFromResidentId/${residentId}`,
      headers: {
        'Bearer': token
      }
    };
    await axios.request(config)
      .then((res) => {
        callback({
          data: res.data
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getKitchenSideSetupOrdersByFacility(Id, callback) {
  return (dispatch) => { request({
      url: `/api/tableside/GetKitchenSideSetupOrders/${Id}`,
      method: 'Get'
    })
      .then((res) => {
        callback({
          data: res.data
        });

      })
      .catch(({ ...error }) => {
        callback({
          error: errorHandler(error)
        })
      })
  };
}

export function reprintSubmittedOrder(Id, callback) {
  return (dispatch) => {
    request({
      url: `/api/tableside/ReprintOrder/${Id}`,
      method: 'post'
    })
      .then((res) => {
        callback({
          data: res.data
        });

      })
      .catch(({ ...error }) => {
        callback({
          error: errorHandler(error)
        })
      })
  };
}

export function submitPrintSummary(orderId, callback) {
  return (dispatch) => {
    request({
      url: `/api/tableside/PrintSummary/${orderId}`,
      method: 'post'
    })
      .then((res) => {
        callback({
          success: "success"
        });
      })
      .catch(({ ...error }) => {
        callback({
          error: errorHandler(error)
        })
      })
  }
}

export function GetResidentTableSelection(residentId, callback) {

  return async (dispatch) => {

    const token = localStorage.getItem("userToken")
    
    let config = {
      baseURL: API_BASE_URL,
      method: 'get',
      maxBodyLength: Infinity,
      url: API_BASE_URL + `/api/Resident/GetResidentTableSelection/${token}/${residentId}`,
      headers: {
        'Bearer': token
      }
    };

    await axios.request(config)
      .then((res) => {
        callback({
          data: res.data
        });
      })
      .catch((error) => {
        console.log(error);
      });
    }
}

export function GetMealAddOn(residentId, tableSideSetupId, callback) {
  return (dispatch) => {
    let data = residentId === 'guest' ? {
      isGuest: true
    } : {
        residentId: residentId,
        isGuest: residentId === 'guest',
        tableSideSetupId: tableSideSetupId
      }
    request({
      url: '/api/tableside/GetMealAddOn/',
      method: 'Post',
      data: data
    })
      .then((res) => {
        callback({
          mealsaddon: res.data
        });

      })
      .catch(({ ...error }) => {
        callback({
          error: errorHandler(error)
        })
      })
  };
}