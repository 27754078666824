import * as actionTypes from './actionTypes';

const initialState = {
    availableMeals: null,
    pendingMeals: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MEALS:
            return Object.assign({}, state, {
                availableMeals: action.payload.availableMeals,
                pendingMeals: action.payload.pendingMeals
            })

        default:
            return state;
    }
}
