import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {ERR_STATUS_500_MSG} from "../../const";
import Button from "@material-ui/core/Button";
import Slide from '@material-ui/core/Slide';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
}

class AlertMsg extends React.Component {

    state = {
        alertOpen: false
    }
    

    handleClose(){
        this.props.handleCloseAlert();
        if (this.props.errorMsg === ERR_STATUS_500_MSG) {
            window.location.href = "/home";
        }
    };


    render() {
        return (
            <Dialog
                open={this.props.alertOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={()=>this.handleClose()}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {"Alert"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {this.props.errorMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>this.handleClose()} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default AlertMsg
