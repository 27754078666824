import React from 'react';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

// icons
import MenuIcon from '@material-ui/icons/Menu';
import RefreshIcon from '@material-ui/icons/Refresh';

class HeaderBar extends React.Component {

    render() {
        let mealNameShow = false;
        if(this.props.history){
            let path = this.props.history.location.pathname;
            if(path.indexOf('takeorder/guest/order') !== -1 || path.indexOf('takeorder/residents/order') !== -1){
                mealNameShow = true;
            }
        }
        
        return (
            <div>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton color="inherit" aria-label="Menu" onClick={() => this.props.toggleMenu()}>
                            <MenuIcon />
                        </IconButton>

                        {mealNameShow ? 
                            <Typography variant="title" color="inherit" style={{flex: 1, textAlign: 'center'}}>
                                {this.props.mealName}
                            </Typography> :
                            <Typography variant="title" color="inherit" style={{flex: 1}}>
                                {this.props.title}
                            </Typography>
                        }

                        <IconButton color="inherit" onClick={() => window.location.reload()} >
                            <RefreshIcon />
                        </IconButton>

                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

export default connect(state => ({
    mealName: state.orderReducer.mealName
}), null)(HeaderBar);
