import * as actionTypes from "./actionTypes";
import request from "utils/request";
import errorHandler from "utils/error";
import { split } from "lodash";

export function login(email, password, callback) {
  return (dispatch) => {
    request({
      url: `/api/account/login2/${window.btoa(email)}/${window.btoa(password)}`,
      method: "Get",
    })
      .then((res) => {
        const result = split(res.data, "|");
        const token = result[0]; //res.data;
        dispatch({ type: actionTypes.LOGIN_SUCCESS, payload: { token: token, email: email } });
        callback({
          success: true,
        });
      })
      .catch((err) => {
        callback({
          error: errorHandler(err),
        });
      });
  };
}

export function logout() {
  return {
    type: actionTypes.LOGOUT,
  };
}

export function getUserData(token, callback) {
  return (dispatch) => {
    Promise.all([
      request({
        url: `/api/diningarea/all/${token}`,
        method: "Get",
      }),
      request({
        url: `/api/resident/all/${token}`,
        method: "Get",
      }),
      request({
        url: `/api/Account/Permissions`,
        method: "Get",
      }),
      request({
        url: `/api/tableside/HeldOrdersV2`,
        method: "Get",
      }),
    ])
      .then((response) => {
        console.log("getUserData", response);
        dispatch({
          type: actionTypes.GET_USER_DATA_SUCCESS,
          payload: {
            diningAreas: response[0].data,
            residents: response[1].data,
            permissions: response[2].data.Permissions,
          },
        });
        dispatch({
          type: actionTypes.SELECT_AREA,
          payload: response[0].data[0],
        });
        dispatch({
          type: actionTypes.HOLD_ORDER,
          payload: response[3].data,
        });
      })
      .catch(({ ...error }) => {
        callback({
          error: errorHandler(error),
        });
      });
  };
}

export function selectArea(diningArea) {
  return {
    type: actionTypes.SELECT_AREA,
    payload: diningArea,
  };
}
