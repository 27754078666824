import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Select } from "@material-ui/core";
import * as orderActions from 'redux/order/actions';
import { LoadingIndicator } from 'components';

import * as _ from 'lodash';

import MenuItem from "@material-ui/core/MenuItem";
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_FIELD, GridActionsCellItem } from '@mui/x-data-grid-pro';

import { CircularProgress } from '@mui/material';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Typography from "@material-ui/core/Typography";

const MealOptions = [
    { value: 'all', label: 'All' }, 
    { value: 'breakfast', label: 'Breakfast' },
    { value: 'lunch', label: 'Lunch' },
    { value: 'dinner', label: 'Dinner' }
  ]

const styles = theme => ({
    orderName: {
        fontSize: '2rem',
        lineHeight: '40px'
    },
    noItem: {
        marginTop: '100px'
    }
})

const printStyles = {
    generalInfo: {
        display: 'flex',
        paddingBottom: '0px',
        paddingTop: '0px'
    },
    divSpace: {
        paddingBottom: '0px',
        paddingTop: '0px'
    },
    rightText: {
    }
}

class SubmittedOrders extends React.Component {

    constructor(props) {
        super(props);
        this.gridref = React.createRef();

        this.DataColumn = [
            {
                field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
                headerName: '',
                minWidth: 1,
                maxWidth: 1,
                disableColumnMenu: true,
            },
            { field: 'name', headerName: 'Full Name' , flex:0.5, minWidth: 100},
            { field: 'date', headerName: 'Meal Date', flex: 0.5, minWidth: 150,  headerAlign:"left", align: 'left' },
            { field: 'meal', headerName: 'Meal', flex: 0.3, minWidth: 100,  headerAlign:"left", align: 'left'},
            {
                field: 'actions',
                type: 'actions',
                minWidth: 150,
                align: 'left',
                renderCell: (params) => {
                    if (!params?.row?.isactive) {   
                        return [
                            <GridActionsCellItem 
                              icon={<DeleteIcon/>}
                              label='Delete'
                              onClick={() => {this.displayDeleteOrderConfirmationMsg(params.row.orderid)}}
                            />,
                            <GridActionsCellItem
                              style={{ marginLeft: 15}}
                              icon={<EditIcon/>}
                              label="Edit"
                              onClick={() => {this.gotoEdit(params)}}
                             />,
                          ]
                    }
                },
                getActions: () => {
                },
              }
          ]
    }

    state = {
        loading: false,
        errorMsg: '',
        menuOrders: [],
        checkList: [],
        submitted: false,
        allSubmittedOrders: [],
        selectedSubmittedOrder: null,
        submittedOrderById: null,
        mealsel: MealOptions[0].label,
        mealSelIndex: 0,
        groupbySubmittedOrders: [],
        dataRows: [],
        lastRowClicked: null,
        isDataLoading: false,
        detailPanelExpandedRowIds: [],
    }

    componentWillMount() {
        // console.log('componentWillMount');
        let checkList = [];
        this.setState({
            checkList: checkList
        });
    }

    componentDidMount() {

        // console.log('componentDidMount');

        let residents = this.props.residents;

        if (residents && residents.length > 0) {
            this.props.getKitchenSideSetupOrdersByFacility(residents[0].Id, response => {
                if (response.data) {
                    this.setState({
                        allSubmittedOrders: response.data,
                        groupbySubmittedOrders: response.data,
                    })
                }
            })
        }
    }

    displayDeleteOrderConfirmationMsg = (orderid) => {

        const { classes } = this.props;

        this.props.setModalComponent(
        <Grid container justify="center" alignItems="center" direction="column">
            <Grid>
                <Typography variant="headline" component="h2" class={classes.confirmMsg}>
                    Are you sure you want to delete the order?
                </Typography>
            </Grid>
            <Grid container justify="center" style={{ marginTop: 15}}>
                <Button 
                    variant="raised"
                    color="primary"
                    onClick={() => this.deleteRow(orderid)}
                    className={classes.btn}
                >
                    Yes
                </Button>
                <Button
                    style={{ marginLeft: 15}}
                    variant="raised"
                    color="primary"
                    onClick={() => this.props.handleCloseModal()}
                    className={classes.btn}
                >
                    No
                </Button>
            </Grid>
        </Grid>);
    }

    deleteRow = async (orderid) => {

        this.props.handleCloseModal();

        const orders = this.state.groupbySubmittedOrders;

        await this.props.DeleteFutureOrderAsync(orderid, response => {
            if (response.success === "success") {
                const slicedorder = orders.filter(item => item.OrderId !== orderid);

                this.setState({
                    groupbySubmittedOrders: slicedorder
                });
            }
        })

    }

    gotoEdit = async (order) => {
        let residentId = order.row.residentid;
        let orderbyid = null;
        let tablesideid = this.state.allSubmittedOrders.find(x => x.OrderId === order.row.orderid)?.TableSideSetupId;

        if (order.row.name.includes('Guest'))
        {
            residentId = 'guest';
            tablesideid = 0;
        }

        await this.props.getFutureOrders(order.row.orderid, (response) => {
              orderbyid = response.data;
        })

        this.setState({ loading: true });
        this.props.saveEditableOrder(orderbyid);
        
        if (orderbyid.Order != null && orderbyid.Order.ResidentId === 0 && order.row.name) {
            this.props.saveGuestInfo({
                guestName: order.row.name,
                paymentDone: orderbyid.Order.GuestBillingTypeId,
                selectedResident: orderbyid.Order.GuestOfResidentId
            });
            this.props.history.push(`/takeorder/guest/order?from=sp`);
        } else {
            this.props.getActiveMealsByResident(residentId, response => {
                const { activeMeals, error } = response;
                if (error) {
                    var errorMsg = '';
                    if (error === 'Failed') {
                        errorMsg = `Request Failed`;
                    }
                    else {
                        errorMsg = error;
                    }
                    this.setState({
                        loading: false,
                    });
                    this.props.showError(errorMsg);
                }
                if (activeMeals) {
                    this.props.history.push(`/takeorder/residents/order/${residentId}/${tablesideid}?current=true&from=sp`);
                }
            })
        }
    }

    formatDate() {
        var newDate = new Date();

        var sMonth = this.padValue(newDate.getMonth() + 1);
        var sDay = this.padValue(newDate.getDate());
        var sYear = newDate.getFullYear();
        var sHour = newDate.getHours();
        var sMinute = this.padValue(newDate.getMinutes());
        var sAMPM = "AM";

        var iHourCheck = parseInt(sHour.toString(), 10);

        if (iHourCheck > 12) {
            sAMPM = "PM";
            sHour = iHourCheck - 12;
        }
        else if (iHourCheck === 0) {
            sHour = "12";
        }

        sHour = this.padValue(sHour);

        return sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
    }

    padValue(value) {
        return (value < 10) ? "0" + value : value;
    }

    getResidentById(id) {
        let residents = this.props.residents;
        let result = '';
        residents.forEach(resident => {
            if (resident.Id === id) {
                result = resident.Name;
            }
        });
        return result;
    }

    getDiningAreaById(id) {
        let areas = this.props.diningAreas;
        let result = '';
        areas.forEach(area => {
            if (area.Id === id) {
                result = area.Name;
            }
        });
        return result;
    }

    renderPrintItems(menuInfo) {
        let menuList = [];
        _.forEach(menuInfo.modalItems.MenuItems, item => {
            let quantity;
            if (item.Quantity === 0.5) {
                quantity = '1/2';
            } else {
                quantity = item.Quantity;
            }
            menuList.push(
                <div style={printStyles.divSpace}>
                    <span>{`${quantity} - ${item.PortionSize} ${item.RecipeName}`}</span>
                </div>
            )
        });
        _.forEach(menuInfo.modalItems.AlternateItems, item => {
            let quantity;
            if (item.Quantity === 0.5) {
                quantity = '1/2';
            } else {
                quantity = item.Quantity;
            }
            menuList.push(
                <div style={printStyles.divSpace}>
                    <span>{`${quantity} - ${item.PortionSize} ${item.RecipeName}`}</span>
                </div>
            )
        });
        return menuList;
    }

    handleReprint = (orderId) => {
        this.setState({ loading: true });
        this.props.reprintSubmittedOrder(orderId, response => {
            const { data, error } = response;
            this.setState({ loading: false });
            if (data === "") {
                this.props.setModalComponent(
                    <Grid container justify="center" alignItems="center" direction="column">
                        <div>Reprinted</div><br />
                        <div>
                            This order was successfully queued for reprinting. 
                        </div><br />
                        <div>
                            <Button
                                variant="raised"
                                color="primary"
                                onClick={() => this.props.handleCloseModal()}
                            >
                                Ok
                            </Button>
                        </div>
                    </Grid>
                )
            } else {
                this.props.showError(error)
            }
        });
    }

    handlePrintSummary = (orderId) => {
        this.setState({ loading: true });
        this.props.submitPrintSummary(orderId, response => {
            const { success, error } = response;
            this.setState({ loading: false });
            if (success) {
                this.props.setModalComponent(
                    <Grid container justify="center" alignItems="center" direction="column">
                        <div>
                            Summary receipt printed. 
                        </div><br />
                        <div>
                            <Button
                                variant="raised"
                                color="primary"
                                onClick={() => this.props.handleCloseModal()}
                            >
                                Ok
                            </Button>
                        </div>
                    </Grid>
                )
            } else {
                this.props.showError(error)
            }
        });
    }

   onSubmittedOrderClick = async (item) => {

       await this.props.getAllSubmittedOrderByIdV2(item.orderid, (response) => {
           if (response.data) {
               this.setState({
                   selectedSubmittedOrder: item.orderid,
                   submittedOrderById: response.data
               });
           }
       });
    }

    selectedSubmittedOrderItems = (Items) => {
        return Items.map((item, index) => {
            return (
                <React.Fragment>
                    <div key={index} className="detail-flex orderDetailsTableBorder">
                        <div className="flex-9 orderDetailsTableFields"><div className="detail-info">{item.Description}</div></div>
                        <div className="flex-3 orderDetailsTableFields"><div className="detail-info">{item.Quantity}</div></div>
                    </div>          

                    {item.SubItems.map((x)=>(                    
                    <div key={index} className="detail-flex orderDetailsTableBorder">
                        <div className="flex-9 orderDetailsTableFields"><div style={{ marginLeft: '50px' }}  className="detail-info">{x.Description}</div></div>
                        <div className="flex-3 orderDetailsTableFields"><div className="detail-info">{x.Quantity}</div></div>
                    </div>
                    ))}
                </React.Fragment>
            )
        })
    }

    checkForDietOtherSeperator()
    {
        if( (this.state.submittedOrderById.Diet && this.state.submittedOrderById.DietTexture && this.state.submittedOrderById.DietOther) ||
        (this.state.submittedOrderById.Diet && !this.state.submittedOrderById.DietTexture && this.state.submittedOrderById.DietOther) || 
        (!this.state.submittedOrderById.Diet && this.state.submittedOrderById.DietTexture && this.state.submittedOrderById.DietOther))
        {    
            return true;
        }
        
        return false;
    }

    checkForDietTextureSeperator()
    {
        if(this.state.submittedOrderById.Diet && this.state.submittedOrderById.DietTexture)
        {    
            return true;
        }
        
        return false;
    }

    renderPrintOrders() {
        const classes = this.props.classes;
        let menuInfos = this.state.menuOrders;
        let menuList = [];
        menuInfos.forEach(menuInfo => {
            menuList.push(
                <div style={{ padding: '10px 5px 5px 5px', fontSize: '15px' }}>
                    {menuInfo.Order.ResidentId === 0 ?
                        <div style={{ fontSize: '23px', fontWeight: 'bold', paddingBottom: '5px', paddingTop: '5px' }}>
                            {menuInfo.Order.Guest}
                        </div> :
                        <div style={{ fontSize: '23px', fontWeight: 'bold', paddingBottom: '5px', paddingTop: '5px' }}>
                            {this.getResidentById(menuInfo.Order.ResidentId)}
                        </div>
                    }

                    {this.getResidentById(menuInfo.Order.GuestOfResidentId) !== "" &&
                        <div style={printStyles.divSpace}>
                            Guest/Employee: {this.getResidentById(menuInfo.Order.GuestOfResidentId)}
                        </div>
                    }
                    {menuInfo.Order.ResidentId === 0 &&
                        <div style={printStyles.divSpace}>
                            Payment Status: {menuInfo.Order.GuestBillingTypeId === "0" ? 'No Charge' : menuInfo.Order.GuestBillingTypeId === "1" ? 'Payment Received' : 'Bill to Resident' }
                        </div>}
                    <div style={{ borderBottom: '2px solid', paddingBottom: '5px', marginBottom: '5px' }}>
                        {this.getDiningAreaById(menuInfo.Order.DiningAreaId) !== "" &&
                            <div style={printStyles.generalInfo}>
                                <div style={printStyles.rightText}>
                                    Dining Room: &nbsp;
                                </div>
                                {this.getDiningAreaById(menuInfo.Order.DiningAreaId)}
                            </div>
                        }
                        {menuInfo.TableName && menuInfo.TableName !== '' && menuInfo.TableName !== 'N/A' &&
                            <div style={printStyles.generalInfo}>
                                <div style={printStyles.rightText}>
                                    Table: &nbsp;
                                </div>
                                {menuInfo.TableName}
                            </div>
                        }
                        {menuInfo && menuInfo.DietName !== "N/A" && menuInfo.DietName !== "" &&
                            <div style={printStyles.generalInfo}>
                                <div style={printStyles.rightText}>
                                    Diet: &nbsp;
                                </div>
                                {menuInfo.DietName}
                                <span className={classes.generalText}>{menuInfo.DietTextureName !== '' && menuInfo.DietTextureName !== 'Regular' && ` / ${menuInfo.DietTextureName}`}</span>
                            </div>
                        }
                        {menuInfo.Order.ResidentId !== 0 && menuInfo.RoomBed && menuInfo.RoomBed !== '' && menuInfo.RoomBed !== 'N/A' &&
                            <div style={printStyles.generalInfo}>
                                <div style={printStyles.rightText}>
                                    Room Number: &nbsp;
                                </div>
                                {menuInfo.RoomBed}
                            </div>
                        }
                        {menuInfo && menuInfo.Notes && menuInfo.Notes.length > 0 &&
                            <div style={printStyles.generalInfo}>
                                <div style={printStyles.rightText}>
                                    Notes: &nbsp;
                            </div>
                                {menuInfo.Notes.map(item => {
                                    if (menuInfo.Notes.indexOf(item) === menuInfo.Notes.length - 1) {
                                        return item;
                                    } else {
                                        return `${item}, `;
                                    }
                                })}
                            </div>}
                        {}
                        {menuInfo && menuInfo.DietOther !== "N/A" && menuInfo.DietOther !== "" &&
                            <div style={printStyles.generalInfo}>
                                <div style={printStyles.rightText}>
                                    Diet Other:&nbsp;
                                </div>
                                {menuInfo.DietOther}
                            </div>
                        }
                        {menuInfo && menuInfo.FluidRestriction !== "N/A" && menuInfo.FluidRestriction !== "" &&
                            <div style={printStyles.generalInfo}>
                                <div style={printStyles.rightText}>
                                    Restriction: &nbsp;
                                </div>
                                {menuInfo.FluidRestriction}
                            </div>
                        }
                        {menuInfo && menuInfo.AdaptiveEquipment !== "N/A" && menuInfo.AdaptiveEquipment !== "" &&
                            <div style={printStyles.generalInfo}>
                                <div style={printStyles.rightText}>
                                    Adaptive Equip: &nbsp;
                                </div>
                                {menuInfo.AdaptiveEquipment}
                            </div>
                        }
                    </div>

                    {menuInfo && menuInfo.Allergies !== "N/A" && menuInfo.Allergies !== "" &&
                        <div style={printStyles.divSpace}>
                            Allergies: &nbsp;{menuInfo.Allergies}
                        </div>
                    }
                    {this.renderPrintItems(menuInfo)}
                    {menuInfo && menuInfo.Order.Note !== "N/A" && menuInfo.Order.Note !== "" &&
                        <div style={printStyles.divSpace}>
                            Note: &nbsp;{menuInfo.Order.Note}
                        </div>
                    }
                    <div style={{ borderTop: '2px solid', marginTop: '5px', paddingBottom: '5px', paddingTop: '5px', fontSize: '10px' }}>
                        {`Order Taken: ${this.formatDate()}, by ${this.props.email}`}
                    </div>
                    {menuInfos.indexOf(menuInfo) !== menuInfos.length - 1 &&
                        <p style={{ pageBreakBefore: 'always' }}></p>
                    }
                </div>
            )

        })
        return menuList;
    }

    chooseType = event => {
        this.setState({ submitted: event.target.value === 'submitted' });
    }

    selectMeal = event => {
        this.setState({
            mealsel: event.target.value
        })

        if (event.target.value === 'All') {
            this.setState({
                groupbySubmittedOrders: this.state.allSubmittedOrders
            })
            return;
        }

        let meals = _.groupBy(this.state.allSubmittedOrders, (item) => item.MealName + " -- " + item.MealDate);
        var result = Object.entries(meals);

        result.forEach(o => {
            if(o[0] === event.target.value) {

                this.setState({
                    groupbySubmittedOrders: o[1]
                })
                return;
            }
        });

    }

    getDetailPanelContent = (item) => {

        if(!item)
            return  (<div> </div>);

        if (!this.state.groupbySubmittedOrders || this.state.groupbySubmittedOrders.length <= 0)
            return;

        let selorder = this.state.groupbySubmittedOrders.find(o => o.OrderId === item.orderid);

        if (!selorder)
            return null;

        if(!this.state.submittedOrderById)
            return  (<div> </div>);

        return (<div className="orderDetailsWrapper px-3 py-3">
            <div className="orderDetailsContainer">
                <div className="orderDetails"><h5 className="orderDetailsHeading">Resident&nbsp; </h5> <div className="detail-info">{this.state.submittedOrderById.ResidentName}</div></div>
                <div className="orderDetails"><h5 className="orderDetailsHeading">Dining Area&nbsp; </h5> <div className="detail-info">{this.state.submittedOrderById.DiningArea}</div></div>
                <div className="orderDetails"><h5 className="orderDetailsHeading">Table&nbsp; </h5> <div className="detail-info">{this.state.submittedOrderById.TableName}</div></div>
                <div className="orderDetails"><span><h5 className="orderDetailsHeading">Diet &nbsp;</h5></span><span className="detail-info">{this.state.submittedOrderById.Diet}
                    {this.checkForDietTextureSeperator() && '/'}{this.state.submittedOrderById.DietTexture}
                    {this.checkForDietOtherSeperator() && '/'}{this.state.submittedOrderById.DietOther}</span></div>
                <div className="orderDetails"><h5 className="orderDetailsHeading">Room # &nbsp;</h5> <div className="detail-info">{this.state.submittedOrderById.RoomNumber}</div></div>
            </div>
            <div>
                <div className="detail-flex">
                    <div className="flex-9"><h5 className="orderDetailsHeading">Description</h5></div>
                    <div className="flex-3"><h5 className="orderDetailsHeading">Quantity</h5></div>
                </div>
                {this.selectedSubmittedOrderItems(this.state.submittedOrderById.Items)}
                <button className="detail-btn" onClick={() => { this.handleReprint(item.orderid) }}>
                    Reprint
                </button>
                <button className="detail-btn" onClick={() => { this.handlePrintSummary(item.orderid) }}>
                    Print Summary
                </button>
            </div>
        </div>
        );
    }

    handleDetailPanelExpandedRowIdsChange = (newIds) => {

        if (newIds.length > 1) {
            this.setState({detailPanelExpandedRowIds: [newIds[newIds.length - 1]]})
        } else {
            this.setState({detailPanelExpandedRowIds: newIds})
        }
    }

    getDataInGridRowsFormat() 
    {   
        const suborders = this.state.groupbySubmittedOrders;

        if (!suborders || suborders.length <= 0)
            return;

        let rowid = 1;
        let rows = [];

        suborders.forEach (lst => {
            rows.push( {id: rowid, residentid: lst.Id, orderid: lst.OrderId, name: lst.FirstName + " " + lst.LastName, date: lst.MealDate, meal: lst.MealName, isactive: lst.IsActiveOrder } )
            rowid++;
        })

        return (<DataGridPro
                    rows={rows}
                    apiRef={(c) => this.gridref = c }
                    columns={this.DataColumn}
                    getDetailPanelContent={() => this.getDetailPanelContent(this.state.lastRowClicked)}
                    getDetailPanelHeight={() => 'auto'}
                    detailPanelExpandedRowIds={this.state.detailPanelExpandedRowIds}
                    onDetailPanelExpandedRowIdsChange={this.handleDetailPanelExpandedRowIdsChange}
                    rowHeight={30}
                    onRowClick={async (params) => {
                                this.setState({lastRowClicked: params.row, isDataLoading: true})
                                await this.onSubmittedOrderClick(params.row);
                                this.gridref.getDetailPanelContent = this.getDetailPanelContent(params.row);
                                this.gridref.toggleDetailPanel(params.id);
                                this.setState({isDataLoading: false})
                        }}
                />);
    }

    renderMealOptions() {

        let meals = _.groupBy(this.state.allSubmittedOrders, (item) => item.MealName + " -- " + item.MealDate);
        var result = Object.entries(meals);
        let list = [<MenuItem key={'All'} value={'All'}>{'All'}</MenuItem>];

        _.forEach(result, item => {
            list.push(
                <MenuItem key={item[0]} value={item[0]}>{item[0]}</MenuItem>
            )
        })

        return list;
    }

    render() {
        
        // console.log("######################3", this.state.allSubmittedOrders)
        
        return (
            <div>                       
                <iframe id="ifmcontentstoprint" title="pendingprint" style={{ height: "0px", width: "0px", position: "absolute", display: "none" }}></iframe>
                {this.state.loading ? <LoadingIndicator /> : (
                    <div className="paperContent customScrollbar">
                        <Grid container justify="center" alignItems="center">
                            <label style={{ left: "100px"}}>Select Meal Option</label>
                            <Select value={this.state.mealsel} onChange={this.selectMeal} style={{ left: "20px"}}>
                                 {this.renderMealOptions()}
                            </Select>
                        </Grid>
                        <div style={{ height: '90%', width: '100%' }}>
                            {this.state.isDataLoading &&  
                            <CircularProgress size={150} color="secondary" style={{ top: "50%", left: "50%", position: 'absolute' }} />}
                            {this.getDataInGridRowsFormat()}                    
                        </div>
                    </div>
                )}
                <div id="printComponent" style={{ position: 'absolute', top: '-9999px' }}>
                {
                    this.renderPrintOrders()
                }
                </div>
            </div>
        );
    }
}
  
export default connect(state => ({
    submittedOrderArray: state.orderReducer.submittedOrderArray,
    diningAreas: state.authReducer.userData.diningAreas,
    residents: state.authReducer.userData.residents,
    email: state.authReducer.email
}), {
        ...orderActions,
    })(withStyles(styles)(SubmittedOrders))
