import * as actionTypes from './actionTypes';

const initialState = {
    userToken: localStorage.getItem("userToken"),
    userData: null,
    email: localStorage.getItem("email"),
    // diningAreas: localStorage.getItem("diningAreas")!=='undefined' ? JSON.parse(localStorage.getItem("diningAreas")) : []
    selectedArea: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS:
            localStorage.setItem("userToken", action.payload.token);
            localStorage.setItem("email", action.payload.email)
            return Object.assign({}, state, {
                userToken: action.payload.token,
                email: action.payload.email
            })
        case actionTypes.LOGOUT:
            localStorage.removeItem("userToken")
            return Object.assign({}, state, {
                userToken: null
            })
        case actionTypes.GET_USER_DATA_SUCCESS:
            return Object.assign({}, state, {
                userData: action.payload
            })
        case actionTypes.SELECT_AREA:
            return Object.assign({}, state, {
                selectedArea: action.payload
            })
        // case actionTypes.GET_DINING_AREAS_SUCCESS:
        //     localStorage.setItem("diningAreas", JSON.stringify(action.payload.area))
        //     return Object.assign({}, state, {
        //         diningAreas: action.payload.area
        //     })

        default:
            return state;
    }
}
