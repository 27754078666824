import React from "react";
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    progress: {
      margin: 0,
    },
});

class LoadingSpinner extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <CircularProgress size={20} className={classes.progress} />
        );
    }
}

export default withStyles(styles)(LoadingSpinner)
