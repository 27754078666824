import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as orderActions from 'redux/order/actions';

import { LoadingIndicator } from 'components';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import * as _ from 'lodash';

class ActiveMeals extends React.Component {
    state = {
        loading: true,
        activeMeals: null,
        residentInfo: null
    }

    componentDidMount(){
        this.props.getActiveMealsByResident(this.props.residentId, response => {
            const { activeMeals, error } = response;
            if(error){
                var errorMsg = '';
                if(error==='Failed'){
                    errorMsg = `Request Failed`;
                }
                else{
                    errorMsg = error;
                }
                this.setState({
                    loading: false,
                });
                this.props.showError(errorMsg);
            }
            if(activeMeals){
                
                this.setState({
                    loading: false,
                    activeMeals: activeMeals
                });
            }
        })

       this.getResidentInfoAsync(this.props.residentId);
    }

    getResidentInfoAsync = async (item) => {

        await this.props.getResidentInfo(this.props.residentId, (response) => {
            if (response) {
                this.setState({
                    residentInfo: response.data,
                });
            }
        });

    }

    renderActiveMeals(){
        let dayList = [];
        if(this.state.activeMeals!==null){
            let days = this.state.activeMeals.Days;
            if(days.length!==0){
                _.forEach(days, day => {
                    let mealList = []
                    _.forEach(day.Meals, meal => {
                        mealList.push(
                            <Button 
                                key={meal.Name} 
                                variant={meal.Filler ? undefined: "raised"} 
                                disabled={meal.Filler}
                                color = { meal.Name.includes("Current Meal") ? "primary" : "info"}
                                component={Link} to={`/takeorder/residents/order/${this.props.residentId}/${meal.SetupId}?current=${meal.Name.includes('Current Meal')}`} 
                            >
                                {meal.Name}
                            </Button>
                        )
                    });
                    dayList.push(
                        <ListItem key={day.Date}>
                            <Grid container direction="column" justify="center" alignItems="center">
                                <Typography variant="headline" gutterBottom>
                                    {day.Date}
                                </Typography>
                                <Grid container justify="center">
                                    {mealList}
                                </Grid>
                            </Grid>
                        </ListItem>
                    )
                    if(days.indexOf(day)!==days.length-1){
                        dayList.push(
                            <Divider key={day.Date+"divider"}/>
                        )
                    }
                })   
            }
        }
        return <List>{dayList}</List>; 
    }

    render() {
        return (    
        <div>
            {this.state.loading ? <LoadingIndicator /> : (
            <div className="paperContent customScrollbar">
                <Typography variant="h1" component="h2" gutterBottom align="center">
                    {this.state.residentInfo != null ? this.state.residentInfo.Name.split(",").reverse().join(" ") : "Error"}
                </Typography>
                {this.renderActiveMeals()}
            </div>
            )
            }
        </div>
        );
    }
}
export default connect(null, {
    ...orderActions, 
})(ActiveMeals)