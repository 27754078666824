import React from 'react';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

// icons
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import ChatIcon from '@material-ui/icons/Chat';
import PanToolIcon from '@material-ui/icons/PanTool';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

const styles = theme => ({
    padding: {
        padding: `0 ${theme.spacing.unit * 2}px`
    }
})
  
class CustomAppBar extends React.Component {

    state = {
        value: this.props.value
    }

    handleChange = (event, value) => {
        this.setState({ value }, ()=>{
            this.props.onChange(value);
        });
    };

    render() {
        const { value } = this.state;
        const { classes } = this.props;
        return (
            <div>
                <AppBar position="static">
                    <Tabs value={value} onChange={this.handleChange} fullWidth>
                        <Tab label="Resident" icon={<PersonIcon />} />
                        <Tab label="Guest/Employee" icon={<PeopleIcon />} />
                        <Tab label="Cook's Ticket" icon={<ChatIcon />} />
                        <Tab label={
                            this.props.holdedOrderArray.length > 0 ? 
                            <Badge color="secondary" badgeContent={this.props.holdedOrderArray.length} className={classes.padding}>
                                Held Orders
                            </Badge> : 'Held Orders'
                        } icon={<PanToolIcon />} />
                        <Tab label="Submitted" icon={<AssignmentTurnedInIcon />} />
                        {/* <Tab label="Consumption" icon={<PersonIcon />} /> */}
                    </Tabs>
                </AppBar>
            </div>
        );
    }
}

export default connect(state => ({
    holdedOrderArray: state.orderReducer.holdedOrderArray
}), null)(withStyles(styles)(CustomAppBar));
