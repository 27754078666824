import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Colors } from 'const';
import { Checkbox } from "@material-ui/core";
import NumericInput from 'react-numeric-input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from "@material-ui/core/Typography";
import * as _ from 'lodash';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function getModalStyleScroll() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        maxHeight: "90%",
        overflow: 'auto',
    };
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        minWidth: '320px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        borderRadius: 8,
    },
});

let self;
class SubRecipes extends React.Component {
    state = {
        open: true,
        displayScroll: true,
        newSelectedSubRecipes: [{
            ParentRecipeId: '',
            RecipeName: '',
            RecipeId: 0,
            Quantity: 1,
            isAlternateItem: false,
        }],
        prevState: []
    };

    componentDidMount(){
        this.setState({newSelectedSubRecipes: Object.assign([], this.props.InitialSelectedSubRecipe) })
        this.setState({prevState: Object.assign([], this.props.InitialSelectedSubRecipe) })
    }

    static setDisplayScroll() {
        
        if (self != undefined) {
            self.setState({
                displayScroll: true,
            })
         }
    }

    static removeDisplayScroll() {
        self.setState({
            displayScroll: false,
        })
    }

    componentWillReceiveProps(nextProps) {
        if(this.state.newSelectedSubRecipes !== nextProps.InitialSelectedSubRecipe) {
          this.setState({newSelectedSubRecipes: Object.assign([], nextProps.InitialSelectedSubRecipe) })
          this.setState({prevState: Object.assign([], nextProps.InitialSelectedSubRecipe) })
        }
    }

    handleClose(){
        this.setState({
            displayScroll: false,
        })

        const cpy = this.state.prevState.map(item => ({...item}))
        this.setState({newSelectedSubRecipes: cpy })
        this.props.SelectedSubRecipeItems(cpy);
        this.props.handleClose();
    };

    onBtnClickHandle = () => {
        const cpy = this.state.newSelectedSubRecipes.map(item => ({...item}))
        this.setState({prevState: Object.assign([], cpy) });        
        this.props.SelectedSubRecipeItems(cpy);
    }

    selectQuantity = (itm, e) => {
        let tmpsel = this.state.newSelectedSubRecipes.map(item => ({...item}));

        tmpsel.filter( (i) => {
            if(i?.RecipeId === itm.RecipeId && i?.ParentRecipeId === itm.ParentRecipeId 
                    && i?.isAlternateItem === itm.isAlternateItem) {
                i.Quantity = e;
            }
        })
        this.setState({newSelectedSubRecipes: tmpsel});
    }

    handleSubRecipeChange(item, checked, qty) {
        let tmpselectedsubrecipe = this.state.newSelectedSubRecipes.map(item => ({...item}));

        if (!checked) {
            tmpselectedsubrecipe.push({
                ParentRecipeId: item.ParentRecipeId,
                RecipeName: item.RecipeName,
                RecipeId: item.RecipeId,
                Quantity: item.Quantity,
                isAlternateItem: item.isAlternateItem,
            });
        }
        else {
                tmpselectedsubrecipe = tmpselectedsubrecipe?.filter((i) => {
                return i?.RecipeId !== item.RecipeId || i?.ParentRecipeId !== item.ParentRecipeId || (i?.isAlternateItem !== item.isAlternateItem)
            });
        }
        this.setState({ newSelectedSubRecipes: tmpselectedsubrecipe });
    };

    renderSubItemTable = (subrecipe) => {
        const { classes } = this.props;
        let submenuitems = [];
        let tmpselsubrecipes = this.state.newSelectedSubRecipes.map(item => ({...item}));

        let sortedsublist = subrecipe.SubRecipeList.sort(function(a,b){
            return parseInt(a.RecipeId)  - parseInt(b.RecipeId); })

        _.forEach(sortedsublist, item => {
        
        let flg = false;
        let foundsubrecipe = tmpselsubrecipes?.find(x => x?.RecipeId === item.RecipeId 
            && x?.isAlternateItem === subrecipe.IsAlternateRecipe && x?.ParentRecipeId === subrecipe.RecipeID);
        let selectedqty = foundsubrecipe? foundsubrecipe.Quantity : 0;
        let minval = selectedqty > 0 ? 1 : 0;
        item.isAlternateItem = subrecipe.IsAlternateRecipe;

        if(foundsubrecipe)flg = true;

        submenuitems.push(
            <TableRow className={classes.toggleRow} style={{ backgroundColor: (flg !== undefined && flg === true) ? Colors.green : 'white'}} key={item.RecipeID}>
                <TableCell style={{ paddingRight: '0' }}>
                <FormControlLabel
                    control={
                        <Checkbox checked={flg} className={classes.generalText} onClick={() => {
                            this.handleSubRecipeChange(item, flg, selectedqty);}}/>
                        } label={<span className={classes.generalText}>{item.RecipeName}</span>} />
                </TableCell>
                <TableCell container justify="flex-end" style={{ paddingLeft: '0'}}>
                    <NumericInput  strict={true} min={minval} max={5} value={selectedqty} style={{disabled: {flg}, input: { width: '50px', height: '25px'}}} onChange={
                        (e) => this.selectQuantity(item, e)}/>
                </TableCell>
            </TableRow>)})

        return submenuitems;
    }

    renderTableItems = (tempsubmenu) =>{
        const { classes } = this.props;
        let submenuitems = [];

        submenuitems.push(
            <Grid container justify="left" alignItems="flex-start" direction="column">
                <Card className={classes.modalItemContainer}>
                <CardContent>
                    <Typography variant="headline" component="h2">{tempsubmenu.RecipeName}</Typography>
                    <Table>
                        <TableBody>
                            {this.renderSubItemTable(tempsubmenu)}
                        </TableBody>
                    </Table>
                </CardContent>
                </Card>
                <Button
                    style={{marginTop: '10px'}}
                    variant="raised"
                    color="primary"
                    className={classes.btn}
                    onClick={() => this.onBtnClickHandle()}
                >
                    Ok
                </Button>
           </Grid>            
        );

        return submenuitems;
    }

	render() {
        const { classes } = this.props;
        self = this;

		return (
				<Modal	
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.props.open}
                    onClose={()=>this.handleClose()}
                    keepMounted={true}>
                    <span style={this.state.displayScroll? getModalStyleScroll() : getModalStyle()} className={`${classes.paper} ${this.state.displayScroll? "customScrollbar" : ""}`}>
                    {
                        this.props.SubRecipesItem && this.renderTableItems(this.props.SubRecipesItem)
                    }
                    </span>
				</Modal>
		);
	}
}

export default withStyles(styles)(SubRecipes)
