import React from "react";
import { connect } from 'react-redux';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import logo from "assets/imgs/TopLogo.png";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { MuiThemeProvider } from '@material-ui/core/styles';

import "typeface-roboto";
import * as authActions from 'redux/auth/actions';
import { LoadingSpinner, AlertMsg } from 'components';
import { customTheme } from 'const';

class Login extends React.Component {

  state = {
    // email: 'developer@developer.com',
    // password: 'Bermuda5',
    email: '',
    password: '',
    loading: false,
    alertOpen: false,
    errorMsg: '',
  }

  handleCloseAlert = () => {
    this.setState({ alertOpen: false });
  };

  login(){
    this.setState({
      loading: true
    });
    const { email, password } = this.state;
    this.props.login(email, password, (response)=>{
      const { success, error } = response;
      if(error){
        var errorMsg = '';
        if(error==='Failed'){
          errorMsg = `Please check your userid and password and try again. 
          If you feel you have reached this screen in error, please contact DiningRD at 1-800-544-3059.`
        }
        else{
          errorMsg = error;
        }
        this.setState({
          loading: false,
          alertOpen: true,
          errorMsg: errorMsg
        });
      }
      if(success){
        localStorage.setItem('user', this.state.email);
        this.props.history.push('/home');
      }
    })
  }

  render() {
    return (
      <div className="login-container">
        <MuiThemeProvider theme={customTheme}>
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
          </header>
          <List className="login-form">
            <ListItem>
              <TextField 
                label="User Id" 
                hinttext="UserName" 
                type="email" 
                fullWidth={true}
                value={this.state.email}
                onChange={(event)=>this.setState({email: event.target.value})}
              />
            </ListItem>
            <ListItem>
              <TextField 
                label="Password" 
                type="password" 
                fullWidth={true}
                value={this.state.password}
                onChange={(event)=>this.setState({password: event.target.value})}
              />
            </ListItem>
            <ListItem>
              <Button
                variant="raised"
                fullWidth={true}
                type="submit"
                color="primary"
                disabled= {this.state.loading}
                onClick={()=>this.login()}
              >
                {this.state.loading ? 
                  <LoadingSpinner/> : 'Login'
                }
              </Button>
            </ListItem>
          </List>
          
          <AlertMsg errorMsg={this.state.errorMsg} alertOpen={this.state.alertOpen} handleCloseAlert={this.handleCloseAlert}/>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default connect(null, {
    ...authActions, 
})(Login)