import React from "react";
import { connect } from 'react-redux';

import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import * as _ from 'lodash';

import * as orderActions from 'redux/order/actions';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    guestTitle: {
        marginRight: '20px',
    },
    card: {
        width: '98%',
        margin: '1%'
    },
    btn: {
        width: '98%',
        margin: '1%'
    },
    cardItem: {
        margin: '5px',
    }
})

class Guest extends React.Component {

    state = {
        guestName: '',
        paymentDone: '',
        selectedResident: 'no select',
        selectedGuestType: 1
    }

    handleChange = event => {
        this.setState({ paymentDone: event.target.value });
    }

    renderResidentsList() {
        let list = [];
        list.push(
            <MenuItem key={"no select"} value={"no select"}>None selected</MenuItem>
        )
        if(this.props.residents!==null){
            _.forEach(this.props.residents, item => {
                list.push(
                    <MenuItem key={item.Id} value={item.Id}>{item.Name}</MenuItem>
                )
            })
        }
        return list;
    }

    renderGuestsTypeList() {

        let list = [<MenuItem key={1} value={1}>{"Guest"}</MenuItem>, 
                    <MenuItem key={2} value={2}>{"Employee"}</MenuItem>, 
                    <MenuItem key={3} value={3}>{"Other"}</MenuItem>];

        return list;
    }

    selectResident = event => {
        this.setState({
            selectedResident: event.target.value
        })
    }

    selectGuestType = event => {
        this.setState({
            selectedGuestType: event.target.value
        })
    }

    handleSubmit() {
        const { guestName, paymentDone } = this.state;
        if(guestName === '' || paymentDone === '') {
            let errorMsg = 'You should enter the Guest/Employee Name and Payment';
            this.props.showError(errorMsg);
        } 
        else if(this.state.selectedGuestType === 1 && this.state.selectedResident === 'no select') {
            let errorMsg = 'Select Guest/Non-Resident Name for Guest Type';
            this.props.showError(errorMsg);
        }
        else {
            this.props.saveGuestInfo({
                guestName: this.state.guestName,
                paymentDone: this.state.paymentDone,
                selectedResident: this.state.selectedResident,
                selectedGuestType: this.state.selectedGuestType
            });
            this.props.history.push('/takeorder/guest/order');
        }
    }

    render() { 
        const { classes } = this.props;

        return (
            <Grid container justify="center" alignItems="center" direction="column">
                <Card className={classes.card}>
                    <CardContent>
                        <TextField className = {classes.cardItem}
                            placeholder="Guest/Employee Name"
                            fullWidth={true}
                            value={this.state.guestName}
                            onChange={(event)=>this.setState({guestName: event.target.value})}
                            inputProps={{ style: { textAlign: 'center'}, maxLength: 50}}
                        />
                        <Grid container justify="center" alignItems="center" className = {classes.cardItem}>
                            <FormControlLabel
                                control={
                                <Radio
                                    checked={this.state.paymentDone === "0"}
                                    onChange={this.handleChange}
                                    value="0"
                                    name="radio-button-demo"
                                />
                                }
                                label="No Charge"
                            />
                            <FormControlLabel
                                control={
                                <Radio
                                    checked={this.state.paymentDone === "1"}
                                    onChange={this.handleChange}
                                    value="1"
                                    name="radio-button-demo"
                                />
                                }
                                label="Payment Received"
                            />
                            <FormControlLabel
                                control={
                                <Radio
                                    checked={this.state.paymentDone === "2"}
                                    onChange={this.handleChange}
                                    value="2"
                                    name="radio-button-demo"
                                />
                                }
                                label="Bill to Resident"
                            />
                            <FormControlLabel
                                control={
                                <Radio
                                    checked={this.state.paymentDone === "3"}
                                    onChange={this.handleChange}
                                    value="3"
                                    name="radio-button-demo"
                                />
                                }
                                label="Bill to Employee"
                            />
                        </Grid>

                        <Grid container justify="center" alignItems="center" className = {classes.cardItem}>

                            <Typography className={classes.guestTitle}>
                                Ticket Type:
                            </Typography>

                            <Select
                                value={this.state.selectedGuestType}
                                onChange={this.selectGuestType}
                            >
                                { this.renderGuestsTypeList() }
                            </Select>

                            { this.state.selectedGuestType === 1 && <Typography style={{marginLeft: 50}} className={classes.guestTitle}>
                                Guest/Non-Resident of
                            </Typography> }

                            { this.state.selectedGuestType === 1  && <Select
                                value={this.state.selectedResident}
                                onChange={this.selectResident}
                            >
                                { this.renderResidentsList() }
                            </Select> }
                        </Grid>
                    </CardContent>
                </Card>
                <Button
                    variant="raised"
                    color="primary"
                    className={classes.btn}
                    onClick={()=>this.handleSubmit()}
                >
                    Take Order
                </Button>
            </Grid>
        );
    }
}

export default connect(state => ({
    residents: state.authReducer.userData.residents
}), {
    ...orderActions, 
})(withStyles(styles)(Guest))
