// export const GET_RESIDENT_SUCCESS = 'order/GET_RESIDENT_SUCCESS';

export const SAVE_GUEST = 'order/SAVE_GUEST';

export const HOLD_ORDER = 'order/HOLD_ORDER';

export const CLEAR_PENDING = 'order/CLEAR_PENDING';

export const DELETE_PENDING = 'order/DELETE_PENDING';

export const SAVE_MEAL_NAME = 'order/SAVE_MEAL_NAME';

export const SAVE_SUBMITTED_ORDER = 'order/SAVE_SUBMITTED_ORDER';

export const SAVE_EDITABLE_ORDER = 'order/SAVE_EDITABLE_ORDER';

export const UPDATE_RESIDENTS = 'order/UPDATE_RESIDENTS';

export const UPDATE_ACTIVE_MEALS = 'order/UPDATE_ACTIVE_MEALS';

export const TESTTEST = 'order/TESTTEST';
