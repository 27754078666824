import React from 'react';
import { connect } from 'react-redux';
import logo from 'assets/imgs/TopLogo.png';
import Button from '@material-ui/core/Button';
import { HeaderAndMenu } from 'components';
import { Link } from 'react-router-dom';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { customTheme } from 'const';

class Home extends React.Component {

    state = {
        email: "",
    }

    componentDidMount() {
        this.setState({
            email: localStorage.getItem("user") ? localStorage.getItem("user") : ""
        })
    }

    gotoSquareApp(){
        var dataParameter = {
            amount_money: {
              amount:        "500",
              currency_code: "USD"
            },
        
            // Replace this value with your application's callback URL
            callback_url: "http://localhost:3000/home",
        
            // Replace this value with your application's ID
            client_id: "sq0idp-UgyHTfuMdHcGoI6PbFu5jw",
        
            version: "1.3",
            notes: "notes for the transaction",
            options: {
              supported_tender_types: ["CREDIT_CARD","CASH","OTHER","SQUARE_GIFT_CARD","CARD_ON_FILE"]
            }
          };

          console.log("sdf", "square-commerce-v1://payment/create?data=" +
          encodeURIComponent(JSON.stringify(dataParameter)))
        
          window.location =
            "square-commerce-v1://payment/create?data=" +
            encodeURIComponent(JSON.stringify(dataParameter));
    }


    render() {
        return (
            <div className="home-container">
                <MuiThemeProvider theme={customTheme}>
                    <HeaderAndMenu title="DiningRD" />
                    <header className="App-header">
                        <img src={logo} className="App-logo" alt="logo" />
                    </header>

                    <List>
                        <ListItem className="list-item">
                            <Button variant="raised" fullWidth={true} type="submit" color="primary"
                                component={Link} to="/takeorder/residents">
                                Take Orders
                            </Button>
                        </ListItem>
                        {/* <ListItem className="list-item">
                            <Button variant="raised" fullWidth={true} type="submit" color="primary"
                                component={Link} to="/consumption">
                                Record Consumption
                            </Button>
                        </ListItem> */}
                        {this.props.permissions.indexOf("Setup Meals") !== -1 &&
                            <ListItem className="list-item">
                                <Button variant="raised" fullWidth={true} type="submit" color="primary"
                                    component={Link} to="/meal">
                                    Start a Meal
                                </Button>
                            </ListItem>
                        }
                    </List>
                </MuiThemeProvider>

                {/* <a href="intent:#Intent;
                    action=com.squareup.pos.action.CHARGE;
                    package=com.squareup;
                    S.browser_fallback_url=http://localhost:3000/home;
                    S.com.squareup.pos.WEB_CALLBACK_URI=http://localhost:3000/home;
                    S.com.squareup.pos.CLIENT_ID=sq0idp-UgyHTfuMdHcGoI6PbFu5jw;
                    S.com.squareup.pos.API_VERSION=v2.0;
                    i.com.squareup.pos.TOTAL_AMOUNT=100;
                    S.com.squareup.pos.CURRENCY_CODE=USD;
                    S.com.squareup.pos.TENDER_TYPES=com.squareup.pos.TENDER_CARD,com.squareup.pos.TENDER_CASH;
                    end">Start Transaction</a> */}

                {/* <button onClick={()=>this.gotoSquareApp()}>Start Transaction</button> */}
                <div className="activeUserContainer">
                <div className="activeUser">
                    <p>v {process.env.REACT_APP_VERSION}</p>
                    <p>{this.state.email}</p>
                </div>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    permissions: state.authReducer.userData.permissions
}), null)(Home)